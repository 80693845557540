import { Drawer, DrawerHeader } from '@/components/Drawer';
import { WorkGroupT, WorkspaceT } from '@/pages/workgroup/type';
import SpaceAdminDetail from '@/pages/workgroup/tab/iam/SpaceAdminDetail';
import { Tab, Tabs } from '@/components/Tab';
import { DatabaseAdmin } from '@/pages/workgroup/tab/iam//db_user/DatabaseAdmin';
import { SpaceIcon } from '@/pages/home/icons';
import { TabOverrides } from 'baseui/tabs-motion';
import { useState } from 'react';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  group: WorkGroupT;
  space: WorkspaceT;
};

const tabOverrides: TabOverrides = {
  TabPanel: {
    // @ts-ignore
    style: ({ hidden }: { hidden: boolean }) => {
      return {
        paddingLeft: '0',
        paddingRight: '0',
        paddingBottom: '0',
        paddingTop: '0',
        boxSizing: 'border-box',

        flexGrow: hidden ? 0 : 1,
        flexBasis: 0,
        minHeight: 0,
        display: 'flex',
        flexDirection: 'column',
      };
    },
  },
};

export default function SpaceUserManagement({ isOpen, onClose, group, space }: Props) {
  const [key, setKey] = useState('Users');
  const [, theme] = useStyletron();

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerHeader>
        <SpaceIcon width={24} height={24} />
        {space.name}
      </DrawerHeader>
      <Tabs
        activeKey={key}
        onChange={({ activeKey }) => {
          setKey(activeKey as string);
        }}
        activateOnFocus={false}
        overrides={{
          TabList: {
            style: {
              backgroundColor: theme.colors['background.primary'],
            },
          },
        }}
      >
        <Tab title="Users" key="Users" overrides={tabOverrides}>
          <SpaceAdminDetail group={group} space={space} onClose={onClose} />
        </Tab>
        <Tab title="Database Access" key="Database Access" overrides={tabOverrides} disabled={!space.canAccess}>
          <DatabaseAdmin group={group} space={space} onClose={onClose} />
        </Tab>
      </Tabs>
    </Drawer>
  );
}

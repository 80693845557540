import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import {
  BackupRestoreStatusError,
  BackupStatus,
  BackupT,
  WorkspaceBackup,
} from '@/pages/workgroup/tab/backupRestore/workspaceBackup';
import { useState } from 'react';
import { Button } from '@tigergraph/app-ui-lib/button';
import { BackupDrawer } from '@/pages/workgroup/tab/backupRestore/BackupDrawer';
import { WorkGroupT, WorkspaceQuotaBackupError, WorkspaceT } from '@/pages/workgroup/type';
import { useQuery, useQueryClient } from 'react-query';
import { WorkspaceSelector } from '@/pages/editor/WorkspaceSelector';
import { TableContainer } from '@/lib/styled';
import EmptyState from '@/pages/workgroup/EmptyState';
import { useOrgContext } from '@/contexts/orgContext.tsx';
import { calculateRoleForSpace } from '@/pages/admin/user/type.ts';
import { useWorkspaceContext } from '@/contexts/workspaceContext';
import { axiosController } from '@/lib/network';
import { Result } from '@/lib/type';
import { StatefulTipsPopover } from '@/components/tipsPopover';
import { TRIGGER_TYPE } from 'baseui/popover';

export function BackupRestore({ group }: { group: WorkGroupT }) {
  const [css] = useStyletron();
  const [isBackupDrawerOpen, setIsBackupDrawerOpen] = useState(false);
  const queryClient = useQueryClient();
  const { userInfo } = useOrgContext();

  // backup workspace list
  // 1. read/write
  // 2. have permission
  const workspaces = group.workspaces.filter(
    (workspace) =>
      workspace.is_rw && calculateRoleForSpace(userInfo.roles, group.workgroup_id, workspace.workspace_id) !== 'users'
  );

  const [currentWorkspace, setCurrentWorkspace] = useState<WorkspaceT | undefined>(workspaces[0]);

  const workspaceFilter = (workspace: WorkspaceT) => {
    return workspaces.find((w) => w.workspace_id === workspace.workspace_id) ? true : false;
  };

  const workgroupId = group.workgroup_id;
  const workspaceId = currentWorkspace?.workspace_id;
  const { data } = useQuery(
    ['getBackupList', workspaceId],
    async () => {
      const response = await axiosController.get<Result<BackupT[]>>(
        `/v2/workgroups/${workgroupId}/workspaces/${workspaceId}/backups`
      );
      return response.data;
    },
    {
      enabled: !!workspaceId && !!workgroupId,
    }
  );

  const { orgQuota } = useWorkspaceContext();
  const backups = data?.Result || [];
  const manualBackUnfailed = backups.filter(
    (backup) =>
      !backup.is_automatic && ![BackupStatus.BACKUP_FAILED, BackupStatus.BACKUP_FORBIDDEN].includes(backup.status)
  );

  const disableBackupDueQuota = orgQuota?.workspace_manual_backup_count_limit
    ? orgQuota?.workspace_manual_backup_count_limit <= manualBackUnfailed.length
    : false;
  const disableBackupRestoreDueStatus = !currentWorkspace || currentWorkspace.status !== 'Active';

  return (
    <>
      {group.workspaces.length == 0 && (
        <TableContainer>
          <EmptyState group={group} toPage="spaces/config" />
        </TableContainer>
      )}
      {group.workspaces.length > 0 && (
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '16px',
          })}
        >
          <WorkspaceSelector
            isLocalSelector={true}
            enableAllSelect={true}
            workspace={currentWorkspace}
            onWorkspaceChange={setCurrentWorkspace}
            workgroupId={group.workgroup_id}
            filterWorkspace={workspaceFilter}
          />
          {disableBackupRestoreDueStatus || disableBackupDueQuota ? (
            <StatefulTipsPopover
              content={
                disableBackupDueQuota ? (
                  <WorkspaceQuotaBackupError orgQuota={orgQuota!} />
                ) : (
                  <BackupRestoreStatusError />
                )
              }
              triggerType={TRIGGER_TYPE.hover}
              overrides={{
                Body: {
                  style: {
                    maxWidth: '450px',
                  },
                },
              }}
            >
              <Button
                overrides={{
                  BaseButton: {
                    style: {
                      opacity: 0.5,
                    },
                  },
                }}
                size="large"
              >
                Backup Now
              </Button>
            </StatefulTipsPopover>
          ) : (
            <Button
              disabled={!currentWorkspace}
              onClick={() => {
                setIsBackupDrawerOpen(true);
              }}
              size="large"
            >
              Backup Now
            </Button>
          )}
        </div>
      )}
      {currentWorkspace ? (
        <WorkspaceBackup
          workgroup={group}
          workspace={currentWorkspace}
          disableBackupRestoreDueStatus={disableBackupRestoreDueStatus}
        />
      ) : (
        <div
          className={css({
            textAlign: 'center',
            padding: '32px',
          })}
        >
          Please select a active workspace.
        </div>
      )}
      {group.workspaces.length > 0 && isBackupDrawerOpen && (
        <BackupDrawer
          onBackupSuccess={() => {
            setIsBackupDrawerOpen(false);
            queryClient.fetchQuery(['getBackupList', currentWorkspace?.workspace_id]);
            queryClient.invalidateQueries(['group', currentWorkspace?.workgroup_id]);
          }}
          isOpen={isBackupDrawerOpen}
          onClose={() => setIsBackupDrawerOpen(false)}
          workgroupId={group.workgroup_id}
          workspaceId={currentWorkspace?.workspace_id as string}
        />
      )}
    </>
  );
}

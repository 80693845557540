import { useWorkspaceContext } from '@/contexts/workspaceContext';
import { SpaceIcon } from '@/pages/home/icons';
import { StatusIndicator } from '@/pages/workgroup/tab/General';
import { WorkspaceT, isStatusError, isStatusIdle, isStatusPaused, isStatusPending } from '@/pages/workgroup/type';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Select } from '@tigergraph/app-ui-lib/select';
import { OnChangeParams, Option, StyledDropdownContainer } from 'baseui/select';
import { CSSProperties, forwardRef, PropsWithChildren, useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ConfirmResumeWorkspace } from '@/pages/workgroup/tab/SettingMenu';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import IconButton from '@/components/IconButton';
import { AccessBlocked } from '@/pages/workgroup/icon';
import { TRIGGER_TYPE } from 'baseui/popover';
import { PLACEMENT } from 'baseui/toast';
import { Button } from '@tigergraph/app-ui-lib/button';
import { SIZE } from 'baseui/button';
import { expand } from 'inline-style-expand-shorthand';
import { CreateWorkspaceModal, useCreateWorkspace } from '@/components/CreateWorkspaceModal';
import { useOrgContext } from '@/contexts/orgContext';
import { isGroupAdmin, isOrgAdmin } from '@/pages/admin/user/type';
import { useQueryGetGroups } from '@/pages/workgroup/hook';

export interface WorkspaceSelectorProps {
  // controlled mode will not effect the global workspace
  isLocalSelector?: boolean;
  workspace?: WorkspaceT;
  onWorkspaceChange?: (workspace: WorkspaceT) => void;

  // scope the workspaces to a specific workgroup
  workgroupId?: string;

  filterWorkspace?: (workspace: WorkspaceT) => boolean;
  width?: string;
  enableAllSelect?: boolean;

  solution?: string;
}

export function useCanCreateWorkspaceInWorkspaceSelector() {
  const { userInfo } = useOrgContext();
  const orgAdmin = isOrgAdmin(userInfo.roles);
  const { data: groups } = useQueryGetGroups({ enabled: !orgAdmin });
  const canCreateWorkspace = orgAdmin || groups?.Result?.some((g) => isGroupAdmin(userInfo.roles, g.workgroup_id));

  return {
    canCreateWorkspace,
  };
}

export function WorkspaceSelector({
  isLocalSelector,
  workspace,
  onWorkspaceChange,
  workgroupId,
  width,
  filterWorkspace,
  enableAllSelect,
  solution,
}: WorkspaceSelectorProps) {
  const [css, theme] = useStyletron();
  const navigate = useNavigate();

  const { currentWorkspace, isLoading, workspaces, my_ip } = useWorkspaceContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showResume, setShowResume] = useState(false);
  const [selectWorkspace, setSelectWorkspace] = useState<WorkspaceT | null>(null);
  const [isCreateWorkspaceModalOpen, setCreateWorkspaceModalOpen] = useState(false);
  const { canCreateWorkspace } = useCanCreateWorkspaceInWorkspaceSelector();
  const { adminGroups } = useCreateWorkspace();

  const getWorkspaces = useCallback(
    (workspaces: WorkspaceT[]) => {
      return (
        workspaces
          .filter((space) => (workgroupId ? space.workgroup_id === workgroupId : true))
          // filter out error workspaces
          .filter((workspace) => enableAllSelect || !isStatusError(workspace.status))
          .filter(filterWorkspace || (() => true))
          .map((workspace) => ({
            ...workspace,
            disabled: enableAllSelect ? false : isStatusPending(workspace.status) || !workspace.canAccess,
          }))
      );
    },
    [workgroupId, filterWorkspace, enableAllSelect]
  );

  const handleSelect = (params: OnChangeParams) => {
    const workspace = params.value[0] as WorkspaceT;

    // if the workspace is pending, do not change the workspace
    if (!enableAllSelect) {
      if (isStatusError(workspace.status)) {
        return;
      }
      if (isStatusPending(workspace.status)) {
        return;
      }
      if (isStatusPaused(workspace.status) || isStatusIdle(workspace.status)) {
        setShowResume(true);
        setSelectWorkspace(workspace);
        return;
      }
    }

    if (onWorkspaceChange) {
      onWorkspaceChange(workspace);
      return;
    }

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('workspace_id', workspace.workspace_id);
    setSearchParams(newSearchParams, {
      replace: true,
    });
  };

  const getLabel = ({ option, isValue }: { option?: Option; isValue?: boolean }) => {
    // need explicit false to show ip access block
    const disableAccess = !enableAllSelect && option!.canAccess === false;

    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        })}
      >
        {isValue && (
          <div
            className={css({
              minWidth: '12px',
              color: theme.colors.gray800,
            })}
          >
            <SpaceIcon />
          </div>
        )}
        <span
          className={css({
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
            marginRight: 'auto',
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
          })}
        >
          <StatefulPopover
            triggerType={TRIGGER_TYPE.hover}
            placement={PLACEMENT.bottom}
            content={option?.workgroup_name}
          >
            <span
              className={css({
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
              })}
            >
              {option!.workgroup_name}
            </span>
          </StatefulPopover>
          <span>/</span>
          <StatefulPopover triggerType={TRIGGER_TYPE.hover} placement={PLACEMENT.bottom} content={option?.name}>
            <span
              className={css({
                fontSize: '14px',
              })}
            >
              {option!.name}
            </span>
          </StatefulPopover>
          {disableAccess ? (
            <StatefulPopover
              content={`Your IP ${my_ip} is not allowed to access this workspace`}
              triggerType="hover"
              ignoreBoundary={true}
            >
              <IconButton
                className={css({
                  marginRight: isValue ? '-6px' : 0,
                })}
              >
                <AccessBlocked />
              </IconButton>
            </StatefulPopover>
          ) : null}
        </span>
        <StatusIndicator status={option!.status} size="compact" errMessage={option!.err_message} />
      </div>
    );
  };

  return (
    <>
      <Select
        getOptionLabel={getLabel}
        getValueLabel={({ option }) => getLabel({ option, isValue: true })}
        isLoading={isLoading}
        options={getWorkspaces(workspaces)}
        value={isLocalSelector ? (workspace ? [workspace] : []) : currentWorkspace ? [currentWorkspace] : []}
        valueKey="workspace_id"
        labelKey="name"
        placeholder="Select a workspace"
        onChange={handleSelect}
        clearable={false}
        searchable={false}
        overrides={{
          Root: {
            style: {
              width: width || '400px',
              marginRight: '16px',
            },
          },
          Popover: {
            props: {
              ignoreBoundary: true,
              popperOptions: {
                modifiers: {
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                },
              },
              overrides: {
                Body: {
                  style: {
                    zIndex: 1,
                  },
                },
              },
            },
          },
          IconsContainer: {
            props: {
              // E2E test locator
              'aria-label': 'workspace-select',
            },
          },
          Dropdown: {
            style: {
              maxHeight: '300px',
              boxShadow: 'none',
              borderBottom: canCreateWorkspace ? 'None' : undefined,
              marginBottom: '0',
              borderBottomLeftRadius: '0',
              borderBottomRightRadius: '0',
            },
          },
          DropdownContainer: {
            component: forwardRef(({ children, style, ...props }: PropsWithChildren<{ style: CSSProperties }>, ref) => {
              return (
                <StyledDropdownContainer
                  {...props}
                  ref={ref}
                  style={{
                    ...style,
                    boxShadow: 'rgba(0, 0, 0, 0.1) -2px -2px 6px, rgba(0, 0, 0, 0.1) 2px 2px 6px',
                  }}
                >
                  {children}
                  {canCreateWorkspace && (
                    <Button
                      kind="secondary"
                      size={SIZE.compact}
                      onClick={() => {
                        if (adminGroups && adminGroups.Result?.length === 0) {
                          const searchParams = new URLSearchParams();
                          let queryString = '';
                          if (solution) {
                            searchParams.set('solution', solution);
                            queryString = `?${searchParams.toString()}`;
                          }
                          navigate(`/groups/new${queryString}`);
                          return;
                        }
                        setCreateWorkspaceModalOpen(true);
                      }}
                      overrides={{
                        BaseButton: {
                          style: {
                            width: '100%',
                            ...expand({
                              padding: '8px',
                              border: `1px solid ${theme.colors.divider}`,
                              fontWeight: 500,
                              borderRadius: 0,
                            }),
                            color: theme.colors['text.link'],
                            backgroundColor: theme.colors['background.primary'],
                          },
                        },
                      }}
                    >
                      <span
                        className={css({
                          width: '100%',
                          textAlign: 'left',
                          fontSize: '14px',
                        })}
                      >
                        Create Workspace
                      </span>
                    </Button>
                  )}
                </StyledDropdownContainer>
              );
            }),
          },
        }}
      />
      {selectWorkspace ? (
        <ConfirmResumeWorkspace
          isOpen={showResume}
          workspace={selectWorkspace}
          onClose={() => {
            setShowResume(false);
            setSelectWorkspace(null);
          }}
        />
      ) : null}
      {canCreateWorkspace && (
        <CreateWorkspaceModal
          solution={solution}
          isOpen={isCreateWorkspaceModalOpen}
          onClose={() => setCreateWorkspaceModalOpen(false)}
        />
      )}
    </>
  );
}

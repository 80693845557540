import { CustomTheme } from '@tigergraph/app-ui-lib/Theme';
import { PopoverOverrides } from 'baseui/popover';

export const popOverrides: PopoverOverrides = {
  Body: {
    style: ({ $theme }) => {
      const theme = $theme as CustomTheme;
      return {
        marginLeft: '0px',
        boxShadow: theme.colors['shadow.popup'],
        border: `1px solid ${theme.colors.divider}`,
      };
    },
  },
  Inner: {
    style: ({ $theme }) => {
      const theme = $theme as CustomTheme;
      return {
        padding: '16px 16px 24px 16px',
        backgroundColor: theme.colors['background.primary'],
      };
    },
  },
};

import useEditorTheme from '@/pages/editor/useEditorTheme';
import ReactCodeMirror from '@uiw/react-codemirror';
import { Tab, Tabs } from 'baseui/tabs-motion';
import { FileTabStyleObject, FileTabsStyleObject } from '@/pages/editor/file/styleObject';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { cmOptions } from '@/pages/workgroup/tab/restPP/type';
import { cpp } from '@codemirror/lang-cpp';
import { useMemo, useState } from 'react';

export default function UDF({
  exprFunction,
  exprUtil,
  tokenBank,
  onExprFunctionChanged,
  onExprUtilChanged,
  onTokenBankChanged,
}: {
  exprFunction: string;
  exprUtil: string;
  tokenBank: string;
  onExprFunctionChanged: (value: string) => void;
  onExprUtilChanged: (value: string) => void;
  onTokenBankChanged: (value: string) => void;
}) {
  const [css, theme] = useStyletron();
  const editorTheme = useEditorTheme();
  const [activeKey, setActiveKey] = useState('ExprFunctions.hpp');

  const extensions = useMemo(() => {
    return [cpp()];
  }, []);

  return (
    <Tabs
      renderAll
      activeKey={activeKey}
      onChange={({ activeKey }) => {
        setActiveKey(activeKey as string);
      }}
      overrides={{
        ...FileTabsStyleObject(theme, undefined, 'auto'),
        TabList: {
          style: {
            height: '57px',
            paddingTop: '20px',
            paddingBottom: '0px',
            marginBottom: '0px',
          },
        },
      }}
    >
      <Tab key="ExprFunctions.hpp" overrides={FileTabStyleObject(theme)} title="ExprFunctions.hpp">
        <div
          className={css({
            border: `1px solid ${theme.colors['border.tertiary']}`,
            borderTop: 'none',
          })}
        >
          <ReactCodeMirror
            value={exprFunction}
            onChange={(value) => onExprFunctionChanged(value)}
            basicSetup={cmOptions}
            theme={editorTheme}
            minHeight="300px"
            extensions={extensions}
          />
        </div>
      </Tab>
      <Tab key="ExprUtil.hpp" overrides={FileTabStyleObject(theme)} title="ExprUtil.hpp">
        <div
          className={css({
            border: `1px solid ${theme.colors['border.tertiary']}`,
            borderTop: 'none',
          })}
        >
          <ReactCodeMirror
            value={exprUtil}
            onChange={(value) => onExprUtilChanged(value)}
            basicSetup={cmOptions}
            theme={editorTheme}
            minHeight="300px"
            extensions={extensions}
          />
        </div>
      </Tab>
      <Tab key="TokenBank.cpp" overrides={FileTabStyleObject(theme)} title="TokenBank.cpp">
        <div
          className={css({
            border: `1px solid ${theme.colors['border.tertiary']}`,
            borderTop: 'none',
          })}
        >
          <ReactCodeMirror
            value={tokenBank}
            onChange={(value) => onTokenBankChanged(value)}
            basicSetup={cmOptions}
            theme={editorTheme}
            minHeight="300px"
            extensions={extensions}
          />
        </div>
      </Tab>
    </Tabs>
  );
}

import { useState, useCallback, useMemo } from 'react';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import {
  LoadToEdgeData,
  LoadToVertexData,
  LoadingJobData,
  OneColumnMappingDataSourceColumnSource,
  OneColumnMappingGraphEntityTarget,
  OneColumnMappingMappingWidgetSource,
  OneColumnMappingMappingWidgetTarget,
  OneColumnMappingTargetType,
  SourceType,
} from '@tigergraph/tools-models/loading-job';
import { Table } from '@tigergraph/app-ui-lib/table';
import { Attribute } from '@tigergraph/tools-models/topology';
import { ValidateResult } from '@tigergraph/tools-models/utils';
import { AttributeSourceSelect } from '@/pages/ingestion/loadData/configureMapping/AttributeSourceSelect';
import { AttributeType } from '@/utils/schemaDesigner/data';
import { Checkbox } from '@tigergraph/app-ui-lib/checkbox';
import { buildAttributeEditorRow } from '@/components/schemaDesigner/attributeEditor/buildAttributeEditorRow';
import TooltipLabel from '@/components/TooltipLabel';
import { Button } from '@tigergraph/app-ui-lib/button';
import { randomString } from '@/utils/schemaDesigner';
import { DefaultAttributeType } from '@/utils/schemaDesigner/data';
import { expand } from 'inline-style-expand-shorthand';
import { MdDeleteOutline } from 'react-icons/md';
import { Plus } from 'baseui/icon';
import ErrorMessage from '@/components/ErrorMessage';
import ConfirmModal from '@/components/ConfirmModal';
import { mergeOverrides } from 'baseui';
import { SchemaAttributeTableWithSelect } from '@/utils/schemaDesigner/styleObject';
import { Overrides } from 'baseui/overrides';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { TRIGGER_TYPE } from 'baseui/popover';

export interface AttributeEditorProps {
  primaryAttrs: Attribute[];
  attributes: Attribute[];
  validateResult: ValidateResult | undefined;
  mappingValidateResult?: ValidateResult | undefined;
  loadingJob?: LoadingJobData;
  loadingStatement?: LoadToVertexData | LoadToEdgeData;
  readOnly?: boolean;
  maxHeight?: string;
  onSave: (attributes: Attribute[]) => void;
  onDeleteLoadingStatementMappings?: (indices: number[]) => void;
  onChangeLoadingStatementMapping?: (
    mapping: {
      mappingSource: OneColumnMappingDataSourceColumnSource | OneColumnMappingMappingWidgetSource;
      mappingTarget: OneColumnMappingGraphEntityTarget | OneColumnMappingMappingWidgetTarget;
    }[]
  ) => void;
}

export default function AttributeEditor(props: AttributeEditorProps) {
  const [css, theme] = useStyletron();
  const {
    primaryAttrs,
    attributes,
    loadingJob,
    loadingStatement,
    readOnly,
    maxHeight,
    validateResult,
    mappingValidateResult,
    onSave,
    onDeleteLoadingStatementMappings,
    onChangeLoadingStatementMapping,
  } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [deleteSelection, setDeleteSelection] = useState<{ [index: number]: boolean }>({});

  const handleAddAttribute = () => {
    const attr = new Attribute();
    attr.name = `newAttr_${randomString(3)}`;
    attr.type.name = DefaultAttributeType;
    attr.defaultValue = '';
    const newAttributes = [...attributes, attr];
    onSave(newAttributes);
  };

  const handleDeleteAttributesConfirm = () => {
    setOpenDialog(true);
  };

  const handleDeleteAttributes = () => {
    const newAttributes = [];
    for (let i = 0; i < attributes.length; i++) {
      if (!(i in deleteSelection)) {
        newAttributes.push(attributes[i]);
      }
    }
    setDeleteSelection({});
    onSave(newAttributes);
    onDeleteLoadingStatementMappings?.(Object.keys(deleteSelection).map(Number));
  };

  const handleDeleteSelection = useCallback((index: number) => {
    setDeleteSelection((prevDeleteSelection) => {
      const isCheck = !prevDeleteSelection[index];
      if (isCheck) {
        return {
          ...prevDeleteSelection,
          [index]: !prevDeleteSelection[index],
        };
      } else {
        delete prevDeleteSelection[index];
        return { ...prevDeleteSelection };
      }
    });
  }, []);

  const handleHeaderDeleteSelection = useCallback(() => {
    if (attributes.length <= primaryAttrs.length) {
      return;
    }
    setDeleteSelection((prevDeleteSelection) => {
      if (Object.keys(deleteSelection).length === attributes.length - primaryAttrs.length) {
        return {};
      } else {
        for (let i = primaryAttrs.length; i < attributes.length; i++) {
          prevDeleteSelection[i] = true;
        }
        return { ...prevDeleteSelection };
      }
    });
  }, [attributes.length, deleteSelection, primaryAttrs.length]);

  const isInvalidAttribute = useMemo(() => {
    return (
      validateResult &&
      !validateResult.success &&
      !validateResult.message?.includes('vertex name') &&
      !validateResult.message?.includes('edge name')
    );
  }, [validateResult]);

  const tableHeader = useMemo(() => {
    const tableHeader = [
      <Checkbox
        key={'delete_checkbox'}
        isIndeterminate={
          Object.keys(deleteSelection).length > 0 &&
          Object.keys(deleteSelection).length !== attributes.length - primaryAttrs.length
        }
        checked={
          Object.keys(deleteSelection).length > 0 &&
          Object.keys(deleteSelection).length === attributes.length - primaryAttrs.length
        }
        disabled={readOnly || attributes.length === primaryAttrs.length}
        onChange={handleHeaderDeleteSelection}
      />,
      'Name',
      'Type',
    ];
    if (primaryAttrs.length === 1) {
      tableHeader.push('Primary Key');
    }
    tableHeader.push('Default Value');
    if (loadingJob && loadingStatement) {
      tableHeader.push('Source');
    }
    return tableHeader;
  }, [
    attributes.length,
    deleteSelection,
    handleHeaderDeleteSelection,
    loadingJob,
    loadingStatement,
    primaryAttrs.length,
    readOnly,
  ]);

  const tableData = useMemo(
    () =>
      attributes.map((attribute, index) => {
        const row = buildAttributeEditorRow({
          css,
          theme,
          attribute,
          deletable: !readOnly && index >= primaryAttrs.length,
          deleting: !!deleteSelection[index],
          readOnly: readOnly || (primaryAttrs.length === 2 && index < primaryAttrs.length),
          isVertex: primaryAttrs.length === 1,
          isPrimaryId: index < primaryAttrs.length,
          attributeNameLabel:
            primaryAttrs.length === 2 && index === 0
              ? 'From'
              : primaryAttrs.length === 2 && index === 1
              ? 'To'
              : undefined,
          handleAttributeChange: (newAttribute) => {
            const newAttributes = [...attributes];
            newAttributes[index] = newAttribute;
            onSave(newAttributes);
            if (['MAP', 'SET', 'LIST', 'UDT'].includes(newAttribute.type.name) && onChangeLoadingStatementMapping) {
              onChangeLoadingStatementMapping([
                {
                  mappingSource: {
                    // @ts-ignore
                    type: SourceType.DataSourceColumn,
                    columnIndex: -1,
                  },
                  mappingTarget: {
                    // @ts-ignore
                    type: OneColumnMappingTargetType.GraphEntity,
                    mappingIndex: index,
                  },
                },
              ]);
            }
          },
          handleDeleteSelection: () => handleDeleteSelection(index),
        });
        if (loadingJob && loadingStatement) {
          row.push(
            <div
              key={`Source_${attribute.name}_${index}`}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            >
              <AttributeSourceSelect
                loadingJob={loadingJob}
                loadingStatement={loadingStatement}
                columnMapping={loadingStatement.mappings[index]}
                disabled={
                  attribute.type.name === AttributeType[AttributeType.LIST] ||
                  attribute.type.name === AttributeType[AttributeType.SET] ||
                  attribute.type.name === AttributeType[AttributeType.MAP] ||
                  attribute.type.name === AttributeType[AttributeType.UDT]
                }
                onChangeSources={(mappingSource) =>
                  onChangeLoadingStatementMapping?.([
                    {
                      mappingSource,
                      mappingTarget: {
                        // @ts-ignore
                        type: SourceType.DataSourceColumn,
                        mappingIndex: index,
                      },
                    },
                  ])
                }
              />
            </div>
          );
        }

        return row;
      }),
    [
      attributes,
      css,
      deleteSelection,
      handleDeleteSelection,
      loadingJob,
      loadingStatement,
      onChangeLoadingStatementMapping,
      onSave,
      primaryAttrs.length,
      readOnly,
      theme,
    ]
  );

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
      })}
    >
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        })}
      >
        <TooltipLabel
          label="Attributes"
          tooltip={
            loadingJob && loadingStatement
              ? 'You can update the attributes name, type and the mappings.'
              : 'You can update the attributes name and type.'
          }
        />
        <StatefulPopover
          triggerType={TRIGGER_TYPE.hover}
          content={Object.keys(deleteSelection).length > 0 ? 'Delete attribute' : 'Add attribute'}
          overrides={{
            Body: {
              style: {
                ...expand({
                  margin: '10px',
                }),
              },
            },
          }}
        >
          <Button
            aria-label={Object.keys(deleteSelection).length > 0 ? 'Delete attribute' : 'Add attribute'}
            onClick={Object.keys(deleteSelection).length > 0 ? handleDeleteAttributesConfirm : handleAddAttribute}
            disabled={readOnly}
            size="default"
            kind={Object.keys(deleteSelection).length ? 'destructive' : 'secondary'}
            overrides={{
              BaseButton: {
                style: {
                  ...expand({
                    padding: '3px',
                  }),
                },
              },
            }}
          >
            {Object.keys(deleteSelection).length > 0 ? <MdDeleteOutline size={16} /> : <Plus size={16} />}
          </Button>
        </StatefulPopover>
      </div>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          rowGap: '8px',
        })}
      >
        <Table
          divider={'horizontal'}
          overrides={mergeOverrides(SchemaAttributeTableWithSelect(theme) as Overrides<any>, {
            Root: {
              style: {
                maxHeight: maxHeight ?? '100%',
                overflow: 'auto',
              },
            },
            TableBodyRow: {
              style: ({ $rowIndex }) => {
                const match = validateResult?.message?.match(/"([^"]*)"/);
                if (match && match[1] !== undefined) {
                  const quotedWord = match[1];
                  if ($rowIndex === attributes.findIndex((attribute) => attribute.name === quotedWord)) {
                    return {
                      border: `2px solid ${theme.colors.negative}`,
                    };
                  }
                }
                return {};
              },
            },
          })}
          columns={tableHeader}
          data={tableData}
        />
        {isInvalidAttribute && <ErrorMessage message={validateResult?.message ?? ''} />}
        {mappingValidateResult && !mappingValidateResult.success && (
          <ErrorMessage message={mappingValidateResult.message ?? ''} />
        )}
      </div>
      <ConfirmModal
        open={openDialog}
        header="Warning"
        body={`Are you sure you want to delete the selected attribute${
          Object.keys(deleteSelection).length > 1 ? 's' : ''
        }?`}
        onConfirm={() => {
          setOpenDialog(false);
          handleDeleteAttributes();
        }}
        onCancel={() => setOpenDialog(false)}
      />
    </div>
  );
}

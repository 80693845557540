import { Return } from '@/lib/type';
import {
  ConfigType,
  getConfig,
  getUdf,
  restartService,
  updateConfig,
  updateUdf,
} from '@/pages/workgroup/form/setting/api';
import { AxiosError } from 'axios';
import { useMutation, useQuery, UseQueryOptions } from 'react-query';

type ConfigReturnType = Awaited<ReturnType<typeof getConfig>>;

export function useQueryConfig(
  nginx_host: string,
  keys: string[],
  options?: Omit<UseQueryOptions<ConfigReturnType, AxiosError>, 'queryKey' | 'queryFn'>
) {
  return useQuery<ConfigReturnType, AxiosError>(
    [nginx_host, 'config', keys],
    async () => {
      return getConfig(nginx_host, keys);
    },
    options
  );
}

// do not invalidate the config cache as UI will only show after isFetching is false
export function useMutationRestartService() {
  return useMutation<Return<void>, AxiosError, { nginx_host: string; services: string[] }>(
    ({ nginx_host, services }) => {
      return restartService(nginx_host, services);
    }
  );
}

export function useMutationUpdateConfig() {
  return useMutation<
    Return<void>,
    AxiosError,
    {
      nginx_host: string;
      config: ConfigType;
      udf: {
        exprUtil?: string;
        exprFunction?: string;
        tokenBank?: string;
      };
    }
  >(async ({ nginx_host, config, udf }) => {
    let results = [await updateConfig(nginx_host, config)];

    const { exprUtil, exprFunction, tokenBank } = udf;
    if (exprFunction) {
      results.push(await updateUdf(nginx_host, 'ExprFunctions', exprFunction));
    }
    if (exprUtil) {
      results.push(await updateUdf(nginx_host, 'ExprUtil', exprUtil));
    }
    if (tokenBank) {
      results.push(await updateUdf(nginx_host, 'TokenBank', tokenBank));
    }

    return results.reduce(
      (acc, result) => {
        if (result.error) {
          acc.error = true;
          acc.message += result.message;
        }
        return acc;
      },
      { error: false, message: '' }
    );
  });
}

export type UDFReturnType = Awaited<ReturnType<typeof getUdf>>;

export function udfKey(nginx_host: string, udfName: string): string[] {
  return [nginx_host, 'udf', udfName];
}

export function useQueryUDF(
  nginx_host: string,
  udfName: string,
  options?: Omit<UseQueryOptions<UDFReturnType, AxiosError>, 'queryKey' | 'queryFn'>
) {
  return useQuery<UDFReturnType, AxiosError>(
    udfKey(nginx_host, udfName),
    async () => {
      return getUdf(nginx_host, udfName);
    },
    options
  );
}

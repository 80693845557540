import { Tab, Tabs } from '@/components/Tab';
import { ErrorDisplay } from '@/components/error';
import { Result } from '@/lib/type';
import QuickInsightLists from '@/pages/dashboard/quickInsightLists';
import SolutionList from '@/pages/marketplace/solution/solution_list';
import EmptyState from '@/pages/workgroup/EmptyState';
import { deleteWorkGroup, getWorkGroupDetail } from '@/pages/workgroup/api';
import { workSpacesAtom, workSpacesROUpdateAtom } from '@/pages/workgroup/atom';
import Database from '@/pages/workgroup/tab/Database';
import General, { GroupTitle } from '@/pages/workgroup/tab/General';
// import Maintenance from '@/pages/workgroup/tab/Maintenance';
import Workspace from '@/pages/workgroup/tab/Workspace';
import AccessManagement from '@/pages/workgroup/tab/iam/AccessManagement';
import { getErrorMessage, shouldShowBackupRestore } from '@/utils/utils';
import { WorkGroupT, isRefreshIng, isStatusError, isStatusPending, isStatusUnknown } from '@/pages/workgroup/type';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { BackupRestore } from '@/pages/workgroup/tab/backupRestore/BackupRestore';
import { useMemo } from 'react';
import NoPermission from '@/components/NoPermission.tsx';
import { Monitor } from '@/pages/workgroup/tab/Monitor.tsx';
import { useOrgContext } from '@/contexts/orgContext.tsx';
import { NewsFeed } from '@/components/newsFeed.tsx';
import { Skeleton } from '@tigergraph/app-ui-lib/skeleton';
import SkeletonIcon from './icons/skeleton.svg?react';
import NetworkAccess from '@/pages/workgroup/tab/networkAccess/index.tsx';
import { calculateRoleForGroup, calculateRoleForSpace } from '@/pages/admin/user/type.ts';
import IconButton from '@/components/IconButton.tsx';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useWorkspaceContext } from '@/contexts/workspaceContext.tsx';
import { useQueryGetGroupMetrics } from '@/pages/workgroup/hook.ts';

export default function WorkGroupPage() {
  const [css, theme] = useStyletron();
  const params = useParams();
  const navigate = useNavigate();
  const { userInfo } = useOrgContext();

  const [searchParams] = useSearchParams();
  const groupID = params.groupID!;

  const location = useLocation();
  const locationState = location.state as {
    skipEmptyState: boolean;
  } | null;

  const [workspaces, setWorkspaces] = useAtom(workSpacesAtom);
  const [roWorkspaces, setROWorkspaces] = useAtom(workSpacesROUpdateAtom);
  const groupQuery = useQuery<Result<WorkGroupT>, AxiosError>(
    ['group', groupID],
    async () => {
      return getWorkGroupDetail(groupID);
    },
    {
      refetchOnWindowFocus: true,
      refetchInterval: 15 * 1000,
      onSuccess: (data) => {
        // check workspace status and add to polling list if needed.
        const results = data?.Result?.workspaces || [];
        for (let workspace of results) {
          if (
            isStatusPending(workspace.status) ||
            isStatusUnknown(workspace.status) ||
            isStatusError(workspace.status)
          ) {
            if (!workspaces.find((item) => item.workspace_id === workspace.workspace_id)) {
              setWorkspaces((workspaces) => workspaces.concat(workspace));
            }
          }
          if (isRefreshIng(workspace.refresh_status)) {
            if (!roWorkspaces.find((item) => item.workspace_id === workspace.workspace_id)) {
              setROWorkspaces((workspaces) => workspaces.concat(workspace));
            }
          }
        }
      },
    }
  );

  const { orgQuota } = useWorkspaceContext();
  const { data: groupMetrics } = useQueryGetGroupMetrics(groupID);

  const group = useMemo(() => {
    if (!groupQuery.data?.Result) {
      return null;
    }
    const group = { ...groupQuery.data?.Result };
    if (!groupMetrics?.Result?.workspaceMetrics) {
      return group;
    }

    for (let metrics of groupMetrics.Result.workspaceMetrics) {
      for (let workspace of group.workspaces) {
        if (workspace.workspace_id === metrics.workspaceID) {
          workspace.tigergraph_memory_usage_percent = metrics.metrics.tigergraph_memory_usage_percent.avgValue;
          workspace.tigergraph_data_memory_usage_percent =
            metrics.metrics.tigergraph_data_memory_usage_percent?.avgValue;
          break;
        }
      }
    }
    return group;
  }, [groupQuery.data?.Result, groupMetrics]);

  const queryClient = useQueryClient();
  const deleteGroupMutation = useMutation<Result<void>, AxiosError, { group_id: string }>(
    (data) => {
      return deleteWorkGroup(data.group_id);
    },
    {
      onSuccess: async (_, data) => {
        queryClient.setQueryData<Result<WorkGroupT[]> | undefined>(['groups'], (oldData) => {
          if (!oldData?.Result) {
            return oldData;
          }
          let newData = {
            ...oldData,
            Result: oldData.Result.filter((item) => item.workgroup_id !== data.group_id),
          };
          return newData;
        });
        navigate('/groups', {
          replace: true,
        });
      },
    }
  );

  if (groupQuery.isLoading) {
    return (
      <div
        className={css({
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        })}
      >
        <div
          className={css({
            display: 'flex',
            gap: '16px',
            padding: '29px 14px 11px',
            borderBottom: `1px solid ${theme.colors.divider}`,
          })}
        >
          {Array(5)
            .fill(0)
            .map((_, index) => (
              <Skeleton key={index} height="16px" width="84px" animation={true} />
            ))}
        </div>
        <div
          className={css({
            flex: 1,
            backgroundColor: theme.colors.backgroundPrimary,
            padding: '40px 32px',
          })}
        >
          <Skeleton height="16px" width="175px" animation={true} />
          <Skeleton
            height="16px"
            width="175px"
            animation={true}
            overrides={{
              Root: {
                style: {
                  marginTop: '32px',
                  marginBottom: '16px',
                },
              },
            }}
          />
          <SkeletonIcon color={theme.colors['background.skeleton']} />
        </div>
      </div>
    );
  }

  if (groupQuery.isError) {
    if (getErrorMessage(groupQuery.error).indexOf('operation denied') > -1) {
      return <NoPermission />;
    }
    return <ErrorDisplay label="Server Error:" error={groupQuery.error} />;
  }

  if (!group) {
    return null;
  }

  if (!orgQuota) {
    return null;
  }

  const onDelete = () => {
    const promise = deleteGroupMutation.mutateAsync({ group_id: group.workgroup_id });
    toast.promise(
      promise,
      {
        loading: 'Deleting workgroup',
        success: (data) => data.Message!,
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  if (group.tg_databases.length === 0 && group.workspaces.length === 0 && !locationState?.skipEmptyState) {
    return (
      <EmptyState
        toPage="spaces/config"
        onDelete={onDelete}
        group={group}
        extraUI={
          <div
            className={css({
              position: 'absolute',
              top: '16px',
              left: '32px',
            })}
          >
            <GroupTitle group={group} hideRegion={true} />
          </div>
        }
      />
    );
  }

  const groupRole = calculateRoleForGroup(userInfo.roles, group.workgroup_id);

  // if has admin on any space, we should show access management tab
  let showAccessManagement = false;
  for (let space of group.workspaces) {
    if (calculateRoleForSpace(userInfo.roles, group.workgroup_id, space.workspace_id) === 'workspace-admins') {
      showAccessManagement = true;
      break;
    }
  }

  const showBackup = shouldShowBackupRestore(userInfo, groupID);
  const showNetwork = groupRole === 'workgroup-admins';

  // !!! need to keep sync with tabs ui
  let tabKeys = ['general', 'workspaces', 'databases', 'monitor', 'solution', 'dataprofiles'];

  if (showAccessManagement) {
    tabKeys.push('access_management');
  }
  if (showBackup) {
    tabKeys.push('backup_and_restore');
  }
  if (showNetwork) {
    tabKeys.push('network_access');
  }

  // default to tabKeys[0]
  const tabKey = searchParams.get('tab') || tabKeys[0];
  const isFirst = tabKey === tabKeys[0];
  const isLast = tabKey === tabKeys[tabKeys.length - 1];

  const handlePrevOrNextTab = (step: number) => {
    const tabIndex = tabKeys.indexOf(tabKey);
    const nextTabKeyIndex = tabIndex + step;
    navigate(`/groups/${group.workgroup_id}?tab=${tabKeys[nextTabKeyIndex]}`, {
      replace: true,
    });
  };

  return (
    <div
      className={css({
        position: 'relative',
        height: '100%',
      })}
    >
      <Tabs
        activeKey={tabKey}
        onChange={({ activeKey }) => {
          navigate(`/groups/${group.workgroup_id}?tab=${activeKey}`, {
            replace: true,
          });
        }}
        activateOnFocus
        overrides={{
          Root: {
            style: {
              position: 'relative',
            },
          },
          TabList: {
            style: {
              paddingTop: '16px',
              marginRight: '16px',
              marginLeft: '16px',
            },
          },
        }}
      >
        <Tab
          title="General"
          key="general"
          overrides={{
            TabPanel: {
              style: {
                backgroundColor: theme.colors['background.tertiary.a'],
              },
            },
          }}
        >
          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              minHeight: '100%',
            })}
          >
            <General group={group} orgQuota={orgQuota} />
            <NewsFeed />
          </div>
        </Tab>
        <Tab title="Workspace" key="workspaces">
          <Workspace group={group} />
        </Tab>
        <Tab title="Database" key="databases">
          <Database group={group} />
        </Tab>
        <Tab title="Monitor" key="monitor">
          <Monitor group={group} />
        </Tab>
        <Tab title="Solution" key="solution">
          <SolutionList group={group} />
        </Tab>
        <Tab
          title="Data Profile"
          key="dataprofiles"
          overrides={{
            TabPanel: {
              style: {
                backgroundColor: theme.colors['background.tertiary.a'],
              },
            },
          }}
        >
          <QuickInsightLists group={group} />
        </Tab>
        {showAccessManagement ? (
          <Tab title="Access Management" key="access_management">
            <AccessManagement group={group} />
          </Tab>
        ) : null}
        {/* <Tab title="Maintenance" key="maintenance">
          <Maintenance group={group} />
        </Tab> */}
        {showBackup && (
          <Tab title="Backup And Restore" key="backup_and_restore">
            <BackupRestore group={group} />
          </Tab>
        )}
        {showNetwork ? (
          <Tab title="Network Access" key="network_access">
            <NetworkAccess group={group} />
          </Tab>
        ) : null}
      </Tabs>
      <div
        className={css({
          width: '16px',
          position: 'absolute',
          height: '32px',
          top: '25px',
          left: '0px',
          borderBottom: `1px solid ${theme.colors.divider}`,
          // zIndex: 1,
        })}
      >
        <IconButton
          className={css({
            width: '100%',
            height: '100%',
            display: isFirst ? 'none' : 'block',
          })}
          onClick={() => {
            handlePrevOrNextTab(-1);
          }}
        >
          <MdKeyboardArrowLeft />
        </IconButton>
      </div>
      <div
        className={css({
          width: '16px',
          position: 'absolute',
          height: '32px',
          top: '25px',
          right: '0px',
          borderBottom: `1px solid ${theme.colors.divider}`,
          // zIndex: 1,
        })}
      >
        <IconButton
          className={css({
            width: '100%',
            height: '100%',
            display: isLast ? 'none' : 'block',
          })}
          onClick={() => {
            handlePrevOrNextTab(1);
          }}
        >
          <MdKeyboardArrowRight />
        </IconButton>
      </div>
    </div>
  );
}

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { RestppDrawer } from '@/pages/workgroup/tab/restPP/RestppDrawer.tsx';
import { DatabaseT, WorkGroupT, WorkspaceT, isStatusActive, isStatusDegraded } from '@/pages/workgroup/type';
import trackUtil from '@/utils/analytics-service.ts';
import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { ChevronDown } from 'baseui/icon';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditorIcon, ExplorerIcon, IngestionIcon, IntegrationIcon, LinkIcon } from '@/pages/home/icons';
import { Body2 } from '@tigergraph/app-ui-lib/typography';
import SchemaIcon from '@/assets/graph-schema.svg?react';
import InsightsIcon from '@/pages/workgroup/tab/icons/insights.svg?react';
import { ConnectionMenuItemAddons } from '@/pages/workgroup/tab/ConnectionMenu/ConnectionMenuAddons';
import clsx from 'clsx';
import { Button } from '@tigergraph/app-ui-lib/button';

const IconContainer = styled('span', ({ $theme }) => {
  return {
    width: '18px',
    height: '18px',
    display: 'flex',
    alignItems: 'center',
  };
});

export default function ConnectionMenu({
  workspace,
  database,
  popover,
  group,
}: {
  workspace: WorkspaceT;
  database?: DatabaseT;
  popover?: boolean;
  group: WorkGroupT;
}) {
  const [css] = useStyletron();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const enabled = isStatusActive(workspace.status) && workspace.canAccess;

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild disabled={!enabled}>
          {popover ? (
            <Button kind="secondary">More</Button>
          ) : (
            <Button
              kind="secondary"
              size="compact"
              disabled={!enabled}
              overrides={{
                BaseButton: {
                  style: {
                    height: '22px',
                  },
                },
              }}
            >
              <span
                className={css({
                  paddingRight: '8px',
                })}
              >
                Connect
              </span>
              <span
                className={css({
                  fontSize: '16px',
                  marginLeft: '-6px',
                  marginRight: '-4px',
                })}
              >
                <ChevronDown size={14} />
              </span>
            </Button>
          )}
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className={clsx(...css({ width: '240px', marginLeft: '160px' }), popover ? 'workspace-popover' : null)}
          align="start"
          userLayer={popover ? false : true}
        >
          {!popover ? (
            <>
              {isStatusActive(workspace.status) && (
                <DropdownMenuItem
                  onClick={() => {
                    navigate('/design-schema?workspace_id=' + workspace.workspace_id);
                  }}
                >
                  <IconContainer $style={{ marginTop: '-2px' }}>
                    <SchemaIcon />
                  </IconContainer>
                  <Body2>{workspace.is_rw ? 'Design Schema' : 'View Schema'}</Body2>
                </DropdownMenuItem>
              )}
              {isStatusActive(workspace.status) && workspace.is_rw && (
                <DropdownMenuItem
                  onClick={() => {
                    navigate('/ingestion?workspace_id=' + workspace.workspace_id);
                  }}
                >
                  <IconContainer>
                    <IngestionIcon />
                  </IconContainer>
                  <Body2>Load Data</Body2>
                </DropdownMenuItem>
              )}
              {isStatusActive(workspace.status) && (
                <DropdownMenuItem
                  onClick={() => {
                    trackUtil.trackEnterGSQLEditor('workspace menu');
                    navigate(`/editor?workspace_id=${encodeURIComponent(workspace.workspace_id)}`);
                  }}
                >
                  <IconContainer>
                    <EditorIcon />
                  </IconContainer>
                  <Body2>Query Editor</Body2>
                </DropdownMenuItem>
              )}
              {isStatusActive(workspace.status) && (
                <DropdownMenuItem
                  onClick={() => {
                    navigate(`/explore?workspace_id=${encodeURIComponent(workspace.workspace_id)}`);
                  }}
                >
                  <IconContainer>
                    <ExplorerIcon />
                  </IconContainer>
                  <Body2>Explore Graph</Body2>
                </DropdownMenuItem>
              )}
              {isStatusActive(workspace.status) && <DropdownMenuSeparator />}
            </>
          ) : null}
          {isStatusActive(workspace.status) && (
            <DropdownMenuItem
              onClick={() =>
                navigate(`/groups/${workspace.workgroup_id}?space_id=${workspace.workspace_id}&tab=dataprofiles`)
              }
            >
              <IconContainer>
                <InsightsIcon />
              </IconContainer>
              <Body2>Get Data Profile</Body2>
            </DropdownMenuItem>
          )}
          {isStatusActive(workspace.status) && !isStatusDegraded(workspace.status) && workspace.is_rw && (
            <DropdownMenuItem
              onClick={() => {
                navigate(`/groups/${workspace.workgroup_id}?tab=solution`);
              }}
              disabled={isStatusDegraded(workspace.status)}
            >
              <IconContainer>
                <IntegrationIcon />
              </IconContainer>
              <Body2>Solution</Body2>
            </DropdownMenuItem>
          )}
          {isStatusActive(workspace.status) && <DropdownMenuSeparator />}
          {!popover ? (
            <>
              <ConnectionMenuItemAddons workspace={workspace} />
              <DropdownMenuSeparator />
            </>
          ) : null}
          <DropdownMenuItem onClick={() => setIsOpen(true)}>
            <IconContainer>
              <LinkIcon />
            </IconContainer>
            <Body2>Connect from API</Body2>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <RestppDrawer
        isOpen={isOpen}
        workspace={workspace}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
}

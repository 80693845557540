import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import ScreenSizeIcon from './icon/screen-size.svg';
import ScreenSizeDarkIcon from './icon/screen-size-dark.svg';
import { styled } from '@tigergraph/app-ui-lib/Theme';
import { useOrgContext } from '@/contexts/orgContext';
import SupportIcon from './icon/support.svg?react';
import { getSupportMailLink } from '@/utils/utils';

export const Label = styled('div', ({ $theme }) => ({
  color: $theme.colors['text.primary'],
}));

export default function ScreenSizeNotSupport() {
  const [css, theme] = useStyletron();

  const { userInfo, currentOrg } = useOrgContext();

  const url = getSupportMailLink({ userInfo, currentOrg });

  return (
    <div
      className={css({
        backgroundColor: theme.colors['background.primary'],
      })}
    >
      <div
        className={css({
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '24px',
          maxWidth: '498px',
          margin: '0 auto',
        })}
      >
        <img src={theme.name === 'dark' ? ScreenSizeDarkIcon : ScreenSizeIcon} alt="fallback" />
        <div
          className={css({
            textAlign: 'center',
          })}
        >
          <h1
            className={css({
              fontSize: '18px',
              fontWeight: 600,
              lineHeight: '32px',
              fontFamily: 'Urbanist',
              marginBottom: '12px',
            })}
          >
            Screen Size Not Supported
          </h1>
          <div
            className={css({
              color: theme.colors['text.secondary'],
              marginBottom: '4px',
              lineHeight: '16px',
              fontSize: '14px',
              textWrap: 'balance',
            })}
          >
            We are sorry but this screen size is not supported. For the best experience, please resize your window or
            switch to a device with a screen width greater than 1024px.
          </div>
        </div>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          })}
        >
          <a
            href={url}
            className={css({
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              borderRadius: '2px',
              padding: '2px 12px',
              backgroundColor: theme.colors['button.background.primary'],
              color: theme.colors['icon.inverse'],
              fontWeight: 500,
              lineHeight: '28px',
            })}
          >
            <SupportIcon color={theme.colors['icon.inverse']} />
            Contact Support
          </a>
        </div>
      </div>
    </div>
  );
}

import { ConfirmStatefulPopover } from '@/components/confirmPopover';
import { ErrorDisplay } from '@/components/error';
import { LoadingIndicator } from '@/components/loading-indicator';
import { FormTitleContainer } from '@/components/styled';
import TooltipLabel from '@/components/TooltipLabel';
import Empty from '@/pages/workgroup/form/setting/schedule/empty';
import ScheduleForm from '@/pages/workgroup/form/setting/schedule/form';
import { useMutationDeleteSchedule, useQueryListSchedule } from '@/pages/workgroup/form/setting/schedule/hook';
import { getWeekDayLabel, Schedule, scheduleTime } from '@/pages/workgroup/form/setting/schedule/type';
import { WorkspaceT, WorkSpaceType } from '@/pages/workgroup/type';
import { getErrorMessage } from '@/utils/utils';
import { Button } from '@tigergraph/app-ui-lib/button';
import { Tag } from '@tigergraph/app-ui-lib/tag';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Plus } from 'baseui/icon';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Trash2Icon, Edit2Icon } from 'lucide-react';
import { StyledToast } from '@tigergraph/app-ui-lib/styledToasterContainer';

export default function ScheduleResize({
  workspace,
  workspaceTypes,
}: {
  workspace: WorkspaceT;
  workspaceTypes: WorkSpaceType[];
}) {
  const [css] = useStyletron();
  const [showForm, setShowForm] = useState(false);
  const [editSchedule, setEditSchedule] = useState<Schedule>();

  const { data, isLoading, isError, error } = useQueryListSchedule(workspace.workgroup_id, workspace.workspace_id);

  return (
    <div>
      <FormTitleContainer>
        <TooltipLabel
          label="Schedule"
          tooltip={`You can create one or more schedules to resize the workspace to a specific workspace size at a specific time.`}
        />
        <Button
          kind="secondary"
          type="button"
          shape="square"
          // disabled={!isSuccess}
          onClick={() => setShowForm(!showForm)}
        >
          <Plus size={20} />
        </Button>
      </FormTitleContainer>
      {isLoading ? <LoadingIndicator /> : null}
      {isError ? <ErrorDisplay label="Server error:" error={error} /> : null}
      {showForm ? (
        <ScheduleForm
          editSchedule={editSchedule}
          onClose={() => {
            setShowForm(false);
            setEditSchedule(undefined);
          }}
          workspace={workspace}
          workspaceTypes={workspaceTypes}
        />
      ) : null}
      {data ? <ScheduleList schedules={data.Result} workspace={workspace} workspaceTypes={workspaceTypes} /> : null}
      {workspace?.tg_version.startsWith('4.2.0') && (
        <div
          className={css({
            paddingTop: '16px',
          })}
        >
          <StyledToast
            hideBorder={true}
            kind="warning"
            closeable={false}
            title="Warning"
            message="Resizing TigerGraph Version 4.2.0 will result in the loss of all vector embeddings. Please note that vector embedding is a preview feature in this version. To avoid data loss, ensure you have a backup of your vector embedding data and be prepared to reload it after the resize is done."
          />
        </div>
      )}
    </div>
  );
}

function ScheduleList({
  schedules,
  workspace,
  workspaceTypes,
}: {
  schedules?: Schedule[];
  workspace: WorkspaceT;
  workspaceTypes: WorkSpaceType[];
}) {
  const [css, theme] = useStyletron();
  if (!schedules || schedules.length === 0) {
    return <Empty />;
  }

  return (
    <div
      className={css({
        borderRadius: '4px',
        border: `1px solid ${theme.colors['border.tertiary']}`,
      })}
    >
      {schedules.map((schedule, index) => (
        <ScheduleItem
          schedule={schedule}
          key={schedule.id}
          showTop={index > 0}
          workspace={workspace}
          workspaceTypes={workspaceTypes}
        />
      ))}
    </div>
  );
}

function ScheduleItem({
  schedule,
  showTop,
  workspace,
  workspaceTypes,
}: {
  schedule: Schedule;
  showTop: boolean;
  workspace: WorkspaceT;
  workspaceTypes: WorkSpaceType[];
}) {
  const [css, theme] = useStyletron();
  const [showEditForm, setShowEditForm] = useState(false);

  const mutationDelete = useMutationDeleteSchedule();

  const onDelete = (id: string) => {
    const promise = mutationDelete.mutateAsync({
      group_id: workspace.workgroup_id,
      workspace_id: workspace.workspace_id,
      id,
    });

    toast.promise(
      promise,
      {
        loading: 'Deleting schedule',
        success: (data) => data.Message!,
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  return (
    <div
      className={css({
        borderTop: showTop ? `1px solid ${theme.colors['border.tertiary']}` : 'none',
      })}
    >
      <div
        className={css({
          padding: '16px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          ':hover': {
            backgroundColor: theme.colors['background.tertiary'],
          },
        })}
      >
        <span
          className={css({
            color: theme.colors['text.secondary'],
            flexShrink: 0,
          })}
        >{`(${schedule.timezone})`}</span>
        <span>{`${scheduleTime(schedule)} set to`}</span>
        <span
          className={css({
            fontWeight: 600,
            flexShrink: 0,
          })}
        >{`${schedule.workspace_type_name}`}</span>
        {schedule.repeat === 'DAILY' ? <Tag closeable={false}>Everyday</Tag> : null}
        {schedule.repeat === 'WEEKDAY' ? (
          <Tag
            closeable={false}
            overrides={{
              Text: {
                style: {
                  maxWidth: 'none',
                },
              },
            }}
          >
            Every Weekday (Monday to Friday)
          </Tag>
        ) : null}
        {schedule.repeat === 'WEEKLY' ? (
          <span
            className={css({
              display: 'flex',
              flexShrink: 1,
              flexGrow: 1,
              flexBasis: 0,
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '4px',
            })}
          >
            {schedule.repeat_on.map((d) => (
              <Tag key={d} closeable={false}>
                {getWeekDayLabel(d).slice(0, 3)}
              </Tag>
            ))}
          </span>
        ) : null}
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            marginLeft: 'auto',
          })}
        >
          <Button shape="square" kind="text" type="button" onClick={() => setShowEditForm(true)}>
            <Edit2Icon size={16} />
          </Button>
          <ConfirmStatefulPopover confirmLabel="Confirm to delete" onConfirm={() => onDelete(schedule.id)}>
            <Button kind="text" shape="square" type="button">
              <Trash2Icon size={16} />
            </Button>
          </ConfirmStatefulPopover>
        </div>
      </div>
      {showEditForm ? (
        <ScheduleForm
          editSchedule={schedule}
          onClose={() => setShowEditForm(false)}
          workspace={workspace}
          workspaceTypes={workspaceTypes}
        />
      ) : null}
    </div>
  );
}

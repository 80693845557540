import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Edge } from '@tigergraph/tools-models/topology';
import { Attribute, ValidateResult } from '@tigergraph/tools-models';
import AttributeEditorContainer from '@/components/schemaDesigner/attributeEditor/AttributeEditorContainer';
import { TypeNameEditor } from '@/components/schemaDesigner/attributeEditor/TypeNameEditor';
import TooltipLabel from '@/components/TooltipLabel';
import AttributeAdvancedSettingModal from '@/components/schemaDesigner/attributeEditor/AttributeAdvancedSettingModal';
import short from 'short-uuid';

export interface EdgeEditorProps {
  isOpen: boolean;
  onClose: () => void;
  initEdge: Edge;
  onSave: (edge: Edge) => ValidateResult;
  readOnly?: boolean;
  attributeReadOnly?: boolean;
  styleReadOnly?: boolean;
  warningMessage?: string[];
}

export function EdgeEditor(props: EdgeEditorProps) {
  const [css, theme] = useStyletron();
  const { isOpen, onClose, initEdge, onSave, readOnly, attributeReadOnly, styleReadOnly, warningMessage } = props;

  const [edgeName, setEdgeName] = useState('');
  const [directed, setDirected] = useState(initEdge.directed);
  const [reverseEdge, setReverseEdge] = useState((initEdge.hasReverseEdge && initEdge.reverseEdge) || '');
  const [attributes, setAttributes] = useState(initEdge.attributes || []);
  const [color, setColor] = useState(initEdge.style?.fillColor || '#cccccc');
  const [fromToVertexTypePairs, setFromToVertexTypePairs] = useState(initEdge.fromToVertexTypePairs || []);
  const [validateResult, setValidateResult] = useState<ValidateResult>({ success: true });
  const [deleteSelection, setDeleteSelection] = useState<{ [index: number]: boolean }>({});
  const [attributeEditorKey, setAttributeEditorKey] = useState(short.generate());
  const divRef = useRef<HTMLDivElement>();

  useEffect(() => {
    setEdgeName(initEdge.name || '');
    const attrs = initEdge.attributes ? [...initEdge.attributes] : [];
    setAttributes(attrs);
    setFromToVertexTypePairs(initEdge.fromToVertexTypePairs ? [...initEdge.fromToVertexTypePairs] : []);
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    setColor(initEdge.style?.fillColor || randomColor);
    setDirected(initEdge.directed || false);
    setReverseEdge((initEdge.hasReverseEdge && initEdge.reverseEdge) || '');
    setValidateResult({ success: true });
    setAttributeEditorKey(short.generate());
  }, [initEdge]);

  const handleSave = useCallback(
    (
      edgeName: string,
      fromToVertexTypePairs: { from: string; to: string }[],
      attributes: Attribute[],
      color: string,
      directed: boolean,
      reverseEdge: string,
      hideValidateResult?: boolean
    ) => {
      const edge = initEdge.clone();
      edge.name = edgeName;
      edge.attributes = attributes;
      edge.style.fillColor = color;
      edge.directed = directed;
      edge.fromToVertexTypePairs = fromToVertexTypePairs;
      edge.hasReverseEdge = !!reverseEdge;
      if (edge.hasReverseEdge) {
        edge.reverseEdge = reverseEdge;
      } else {
        edge.reverseEdge = '';
      }
      edge.setDiscriminatorProperties();
      const res = onSave(edge);
      if (res?.success) {
        initEdge.loadFromGSQLJson(edge.dumpToGSQLJson());
      }
      if (!hideValidateResult) {
        setValidateResult(res);
      }
      return res;
    },
    [initEdge, onSave]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  return (
    <div
      ref={divRef as RefObject<HTMLDivElement>}
      className={css({
        height: '100%',
        width: '100%',
        backgroundColor: theme.colors['background.primary'],
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px',
        padding: '16px 0',
      })}
    >
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        })}
      >
        <TooltipLabel label="Edge Name" tooltip="Selected target edge, you can update the edge name and color." />
        <AttributeAdvancedSettingModal
          mountNode={divRef.current}
          name={edgeName}
          isVertex={false}
          attributes={attributes}
          fromToVertexTypePairs={fromToVertexTypePairs}
          readOnly={readOnly}
          directed={directed}
          reverseEdge={reverseEdge}
          onSaveEdge={(newAttributes, newFromToVertexTypePairs, newReverseEdge) => {
            const pairs = newFromToVertexTypePairs ?? fromToVertexTypePairs;
            const res = handleSave(edgeName, pairs, newAttributes, color, directed, newReverseEdge, true);
            if (res?.success) {
              setAttributes(newAttributes);
              setReverseEdge(newReverseEdge);
              setFromToVertexTypePairs(pairs);
            }
            return res;
          }}
        />
      </div>
      <TypeNameEditor
        isVertex={false}
        typeName={edgeName}
        color={color}
        directed={directed}
        readOnly={readOnly}
        styleReadOnly={styleReadOnly}
        warningMessage={warningMessage}
        validateResult={validateResult}
        onSaveEdge={(newName, newColor, newDirected) => {
          setEdgeName(newName);
          setColor(newColor);
          setDirected(newDirected);
          let tempReverseEdge = reverseEdge;
          if (!directed && newDirected) {
            tempReverseEdge = `reverse_${newName}`;
          } else if (newDirected && tempReverseEdge === `reverse_${edgeName}`) {
            tempReverseEdge = `reverse_${newName}`;
          }
          if (!newDirected) {
            tempReverseEdge = '';
          }
          setReverseEdge(tempReverseEdge);
          handleSave(newName, fromToVertexTypePairs, attributes, newColor, newDirected, tempReverseEdge);
        }}
      />
      <AttributeEditorContainer
        key={attributeEditorKey}
        primaryAttrs={[]}
        attributes={attributes}
        validateResult={validateResult}
        readOnly={attributeReadOnly}
        onSave={(newAttributes) => {
          setAttributes(newAttributes);
          handleSave(edgeName, fromToVertexTypePairs, newAttributes, color, directed, reverseEdge);
        }}
      />
    </div>
  );
}

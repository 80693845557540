import { SelectOverrides } from 'baseui/select';
import { TabOverrides, TabsOverrides } from 'baseui/tabs-motion';
import { CustomTheme, styled } from '@tigergraph/app-ui-lib/Theme';

export const StyledSelectStyleObject = {
  Root: {
    style: {
      width: '100%',
    },
  },
  ControlContainer: {
    style: {
      minHeight: '32px',
    },
  },
  SelectArrow: {
    props: {
      overrides: {
        Svg: {
          style: () => ({
            display: 'none',
          }),
        },
      },
    },
  },
  Placeholder: {
    style: {
      fontSize: '14px',
    },
  },
  DropdownListItem: {
    style: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '12px',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'normal',
      paddingTop: '4px',
      paddingBottom: '4px',
    },
  },
} as SelectOverrides;

export const FileTabsStyleObject = (theme: CustomTheme, heightColor?: string, height?: string) =>
  ({
    Root: {
      style: {
        height: height || '100%',
        transform: 'none', // to fix codemirror autocomplete tooltip position, see https://github.com/codemirror/dev/issues/324
      },
    },
    TabHighlight: {
      style: {
        height: '2px',
        backgroundColor: heightColor ?? theme.colors['background.primary'],
      },
    },
    TabBorder: {
      style: {
        height: '1px',
        backgroundColor: `${theme.colors.divider}`,
        marginTop: '-1px',
      },
    },
    TabList: {
      style: {
        height: '41px',
        paddingTop: '12px',
        paddingBottom: '0px',
        marginRight: '16px',
        marginLeft: '16px',
        marginBottom: '0px',
        minWidth: 'calc(50% - 16px)',
        overflowX: 'scroll',
      },
    },
  } as TabsOverrides);

export const FileTabStyleObject = (theme: CustomTheme, bgColor?: string) =>
  ({
    Tab: {
      style: ({ $isActive }) => ({
        fontSize: '12px',
        fontWeight: $isActive ? 'bold' : 'regular',
        // background: $isActive ? 'white' : `transparent !important`,
        background: $isActive && bgColor ? bgColor : 'transparent',
        boxShadow: $isActive ? `0px -4px 0px ${theme.colors.primaryAction}` : 'none',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderRightWidth: '1px',
        borderRightStyle: 'solid',
        borderLeftWidth: $isActive ? '1px' : '0px',
        borderLeftStyle: 'solid',
        borderBottomWidth: $isActive ? '0px' : '1px',
        borderBottomStyle: 'solid',
        position: 'relative',
        paddingTop: '0px',
        paddingBottom: '0px',
        marginRight: $isActive ? '0px' : '-1px',
        borderImage: $isActive
          ? `linear-gradient(to bottom, ${theme.colors['background.primary']}, ${theme.colors.divider} 20%, ${theme.colors.divider} 90%, ${theme.colors['background.primary']} 99%, ${theme.colors['background.primary']} 100%) 1`
          : `linear-gradient(to bottom, ${theme.colors['background.primary']}, ${theme.colors['background.primary']} 30%, ${theme.colors.divider} 30%, ${theme.colors.divider} 70%, ${theme.colors['background.primary']} 70%, ${theme.colors['background.primary']} 90%, ${theme.colors.divider} 100%) 1`,
        ':first-child': {
          borderLeftWidth: '1px',
        },
      }),
    },
    TabPanel: {
      style: {
        height: 'calc(100% - 52px)',
        paddingRight: '0px',
        paddingLeft: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },
  } as TabOverrides);

export const StyledButton = styled('button', ({ $theme }: { $theme: CustomTheme }) => ({
  paddingLeft: '8px',
  width: '100%',
  display: 'block',
  textAlign: 'left',
  color: $theme.colors['dropdown.text'],
  ':hover': {
    backgroundColor: $theme.colors['dropdown.background.hover'],
  },
  ':disabled': {
    cursor: 'not-allowed',
    color: $theme.colors['dropdown.text.disabled'],
  },
}));

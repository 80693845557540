import { useForm, Controller } from 'react-hook-form';
import { Input } from '@tigergraph/app-ui-lib/input';
import { Select } from '@tigergraph/app-ui-lib/select';
import { Checkbox } from '@tigergraph/app-ui-lib/checkbox';
import { Button } from '@tigergraph/app-ui-lib/button';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { ThemeProvider } from '@/contexts/themeContext.tsx';
import { FormControl } from './FormControl';
import { axiosOrgService } from '@/lib/network';
import { useMutation } from 'react-query';
import trackUtil from '@/utils/analytics-service';
import { showToast } from '@tigergraph/app-ui-lib/styledToasterContainer';
import { AxiosError } from 'axios';
import { getErrorMessage } from '@/utils/utils';
import { useOrgContext } from '@/contexts/orgContext';
import { useNavigate } from 'react-router-dom';

type SurveyFormData = {
  firstName: string;
  lastName: string;
  company: string;
  role: string;
  usage: string;
  useCases: string[];
  otherUseCase?: string;
};

const cases = ['Fraud', 'Anti-Money Laundering', 'Cybersecurity', 'Customer 360', 'Product Recommendation', 'Other'];

export default function Survey() {
  const [css, theme] = useStyletron();
  const { currentOrg, setCurrentOrg } = useOrgContext();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<SurveyFormData>({
    defaultValues: {
      useCases: [],
    },
  });

  const otherUseCase = watch('otherUseCase');

  const surveyMutation = useMutation(
    async (data: SurveyFormData) => {
      let survey = {
        first_name: data.firstName,
        last_name: data.lastName,
        company: data.company,
        role: data.role,
        use_for: data.usage,
        biz_use_case: [
          [
            ...data.useCases.filter((useCase) => useCase !== 'Other'),
            ...(data.otherUseCase ? [data.otherUseCase] : []),
          ].join(','),
        ],
      };

      await submitSurvey(survey);
      await updateOrg(currentOrg?.org_id, true);
    },
    {
      onSuccess: () => {
        trackUtil.trackFinishSurveyForm('Onboard', 'Success');
        setCurrentOrg({
          ...currentOrg,
          marketing_done: true,
        });
        navigate('/');
      },
      onError: (err: AxiosError) => {
        trackUtil.trackFinishSurveyForm('Onboard', 'Fail');
        showToast({
          kind: 'negative',
          message: `Failed to save the data.\n${getErrorMessage(err)}`,
        });
      },
    }
  );

  const onSubmit = (data: SurveyFormData) => {
    surveyMutation.mutate(data);
  };

  return (
    <ThemeProvider>
      <div
        className={css({
          margin: '0 auto',
          marginTop: '54px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          alignItems: 'center',
        })}
      >
        {/* <LogoIcon /> */}
        <div
          className={css({
            padding: '32px 96px 64px',
            width: '720px',
            backgroundColor: theme.colors['background.primary'],
            border: `1px solid ${theme.colors['border.tertiary']}`,
            borderRadius: '2px',
          })}
        >
          <h1
            className={css({
              fontFamily: 'Urbanist',
              fontSize: '24px',
              fontWeight: 600,
              lineHeight: '40px',
              textAlign: 'center',
              marginBottom: '36px',
            })}
          >
            Get started with TigerGraph Savanna
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div
                className={css({
                  display: 'flex',
                  gap: '16px',
                })}
              >
                <div className={css({ flex: 1 })}>
                  <FormControl label="First Name" error={errors?.firstName?.message}>
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{
                        required: 'Please enter your first name.',
                      }}
                      render={({ field }) => <Input {...field} placeholder="Enter your first name" size="large" />}
                    />
                  </FormControl>
                </div>
                <div className={css({ flex: 1 })}>
                  <FormControl label="Last Name" error={errors?.lastName?.message}>
                    <Controller
                      name="lastName"
                      control={control}
                      rules={{
                        required: 'Please enter your last name.',
                      }}
                      render={({ field }) => <Input {...field} placeholder="Enter your last name" size="large" />}
                    />
                  </FormControl>
                </div>
              </div>
              <FormControl label="Your Company" error={errors?.company?.message}>
                <Controller
                  name="company"
                  control={control}
                  rules={{
                    required: 'Please enter your company name.',
                  }}
                  render={({ field }) => (
                    <Input {...field} placeholder="Enter the name of your company or organization" size="large" />
                  )}
                />
              </FormControl>
              <FormControl label="Your Role" error={errors?.role?.message}>
                <Controller
                  name="role"
                  control={control}
                  rules={{
                    required: 'Please select your role.',
                  }}
                  render={({ field: { value, onChange, ref, ...field } }) => (
                    <Select
                      {...field}
                      value={[{ id: value }]}
                      onChange={(params) => {
                        if (params.value.length > 0) {
                          onChange(params.value[0].id);
                        }
                      }}
                      inputRef={ref}
                      clearable={false}
                      deleteRemoves={false}
                      backspaceRemoves={false}
                      placeholder="Select your role or position in the company"
                      size="large"
                      options={[
                        { label: 'BI / Analyst', id: 'BI / Analyst' },
                        { label: 'Business Executive', id: 'Business Executive' },
                        { label: 'Data Engineer', id: 'Data Engineer' },
                        { label: 'Developer / Engineer', id: 'Developer / Engineer' },
                        { label: 'IT / DBA', id: 'IT / DBA' },
                        { label: 'Product Manager', id: 'Product Manager' },
                        { label: 'Marketing', id: 'Marketing' },
                        { label: 'Security', id: 'Security' },
                        { label: 'Technical Executive', id: 'Technical Executive' },
                        { label: 'Startup Founder', id: 'Startup Founder' },
                        { label: 'Student', id: 'Student' },
                        { label: 'Other', id: 'Other' },
                      ]}
                      overrides={{
                        Dropdown: {
                          style: {
                            maxHeight: '300px',
                          },
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
              <FormControl label="How are you going to use TigerGraph Savanna?" error={errors?.usage?.message}>
                <Controller
                  name="usage"
                  control={control}
                  rules={{
                    required: 'Please select how you are going to use TigerGraph Savanna.',
                  }}
                  render={({ field: { value, onChange, ref, ...field } }) => (
                    <Select
                      {...field}
                      value={[{ id: value }]}
                      onChange={(params) => {
                        if (params.value.length > 0) {
                          onChange(params.value[0].id);
                        }
                      }}
                      inputRef={ref}
                      clearable={false}
                      deleteRemoves={false}
                      backspaceRemoves={false}
                      placeholder="Select how you plan to use TigerGraph Savanna"
                      size="large"
                      options={[
                        { label: 'Initial Research', id: 'Initial Research' },
                        { label: 'Testing and Evaluation', id: 'Testing and Evaluation' },
                        { label: 'Production Use Case', id: 'Production Use Case' },
                        { label: 'Other', id: 'Other' },
                      ]}
                    />
                  )}
                />
              </FormControl>
              <FormControl label="What are your use cases for TigerGraph?" error={errors?.useCases?.message}>
                <Controller
                  name="useCases"
                  control={control}
                  rules={{
                    validate(value) {
                      if (value.length === 0 && !otherUseCase) {
                        return 'Please input at least one use case.';
                      }
                    },
                  }}
                  render={({ field }) => (
                    <div
                      className={css({
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                      })}
                    >
                      {cases.map((c) => {
                        if (c === 'Other') {
                          return (
                            <div
                              key={c}
                              className={css({
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                              })}
                            >
                              <Checkbox
                                key={c}
                                {...field}
                                onChange={(e) => {
                                  if (e.currentTarget.checked) {
                                    field.onChange([...field.value, c]);
                                  } else {
                                    field.onChange(field.value.filter((v) => v !== c));
                                  }
                                }}
                                value="Fraud"
                                checked={field.value.includes(c)}
                              >
                                {c}
                              </Checkbox>
                              <Controller
                                name="otherUseCase"
                                control={control}
                                render={({ field }) => (
                                  <Input
                                    {...field}
                                    placeholder="Enter other use cases, use comma as the separator"
                                    size="large"
                                  />
                                )}
                              />
                            </div>
                          );
                        }
                        return (
                          <Checkbox
                            key={c}
                            {...field}
                            onChange={(e) => {
                              if (e.currentTarget.checked) {
                                field.onChange([...field.value, c]);
                              } else {
                                field.onChange(field.value.filter((v) => v !== c));
                              }
                            }}
                            value="Fraud"
                            checked={field.value.includes(c)}
                          >
                            {c}
                          </Checkbox>
                        );
                      })}
                    </div>
                  )}
                />
              </FormControl>
            </div>
            <Button
              type="submit"
              size="large"
              isLoading={surveyMutation.isLoading}
              disabled={surveyMutation.isLoading}
              overrides={{
                BaseButton: {
                  style: {
                    width: '100%',
                    display: 'block',
                    marginTop: '40px',
                    height: '40px',
                  },
                },
              }}
            >
              Continue
            </Button>
          </form>
        </div>
      </div>
    </ThemeProvider>
  );
}

async function submitSurvey(data: {
  first_name: string;
  last_name: string;
  company: string;
  role: string;
  use_for: string;
  biz_use_case: string[];
}) {
  return await axiosOrgService.post(`/orgs/survey`, {
    ...data,
    savanna: true,
  });
}

async function updateOrg(orgId: string, marketing_done: boolean) {
  return await axiosOrgService.put(`/orgs/${orgId}`, {
    marketing_done,
  });
}

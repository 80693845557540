import mixpanel from 'mixpanel-browser';

export enum EventStatus {
  Success = 'Success',
  Fail = 'Fail',
}

export enum AppToolType {
  GraphStudio = 'Graph Studio',
  Insights = 'Insights',
  AdminPortal = 'Admin Portal',
  GraphQL = 'GraphQL',
}

class AnalyticsService {
  constructor() {
    this.init();
  }

  init() {
    mixpanel.init(import.meta.env.VITE_MIX_Panel_Key, {
      opt_out_tracking_by_default: true,
    });
    mixpanel.register({
      'Analytics Version': 1.1,
      Product: 'cloud-on-demand',
      Environment: window.location.origin.includes('tgcloud.io') ? 'production' : 'test',
    });
    mixpanel.opt_in_tracking();
  }

  /**
   * Send events to mixpanel.
   *
   * @param  {string} eventName event name
   * @param  {object} props? event properties
   */
  track(eventName: string, props?: object) {
    mixpanel.track(eventName, props);
  }

  // login complete
  trackLoginComplete(userProfile: { tenantID: string; id: string }) {
    const { tenantID, id } = userProfile;
    if (tenantID && id) {
      mixpanel.identify(tenantID + '_' + id);
    }
    this.track('Login Complete');
  }

  // create workspace part
  trackCreateWorkspace(props: { status: EventStatus }) {
    this.track('Create Workspace', props);
  }

  trackEnterSchemaDesigner() {
    this.track('Enter Schema Designer');
  }

  trackEnterGSQLEditor(from: string) {
    this.track('Enter Query Editor', { from });
  }

  trackEnterDataIngestion() {
    this.track('Enter Load Data');
  }

  trackEnterExploreGraph() {
    this.track('Enter Explore Graph');
  }

  trackEnterAppTools(toolType: AppToolType) {
    this.track('Enter App Tools', { toolType });
  }

  trackFinishSurveyForm(from: string, state: 'Success' | 'Fail') {
    this.track('Finish Survey Form', { from, state });
  }
}

const trackUtil = new AnalyticsService();
export default trackUtil;

import {
  continuedIndent,
  indentNodeProp,
  foldNodeProp,
  languageDataProp,
  defineLanguageFacet,
} from '@codemirror/language';
import { styleTags, tags as t } from '@lezer/highlight';
import { parser as baseParser } from './query.grammar.ts';
import { controlKeywords, keywords, baseTypes, accums, atoms, operators } from './queryKw.ts';
import { Schema } from '@tigergraph/tools-ui/esm/graph/type';

const stmts = [
  'TypedefStmt',
  'VSetVarDeclStmt',
  'AssignmentStmt',
  'GAccumAssignStmt',
  'LAccumAssignStmt',
  'DeclStmt',
  'FuncCallStmt',
  'CaseStmt',
  'ForEachStmt',
  'RaiseStmt',
  'TryStmt',
  'LogStmt',
  'SelectStmt',
  'PrintStmt',
  'ReturnStmt',
  'WhileStmt',
  'IfStmt',
  'DeleteStmt',
  'UpdateStmt',
  'InsertStmt',
  'GAccumAccumStmt',
  'LAccumAccumStmt',
  'LocalVarDeclStmt',
  'DmlSubCaseStmt',
  'DmlSubWhileStmt',
  'DmlSubIfStmt',
  'DmlSubForEachStmt',
  'DmlSubDeleteStmt',
];
const clauses = [
  'FromClause',
  'SampleClause',
  'WhereClause',
  'AccumClause',
  'PostAccumClause',
  'HavingClause',
  'OrderClause',
  'LimitClause',
];

export const parser = baseParser.configure({
  props: [
    languageDataProp.add({
      GSQLQuery: defineLanguageFacet({
        commentTokens: { line: '//', block: { open: '/*', close: '*/' } },
        closeBrackets: { brackets: ['(', '[', '{', "'", '"', '`'] },
      }),
    }),
    indentNodeProp.add({
      GSQLQuery: continuedIndent({}),
      [stmts.join(' ')]: continuedIndent({}),
      [clauses.join(' ')]: continuedIndent({}),
      TryStmt: continuedIndent({ except: /(END|EXCEPTION)\b/i }),
      'WhileStmt DmlSubWhileStmt': continuedIndent({ except: /(END|DO)\b/i }),
      'ForEachStmt DmlSubForEachStmt': continuedIndent({ except: /(END|DO)\b/i }),
      'CaseStmt DmlSubCaseStmt': continuedIndent({ except: /(END)\b/i }),
      'IfStmt DmlSubIfStmt': continuedIndent({ except: /(END|ELSE|DO|THEN)\b/i }),
    }),
    foldNodeProp.add({
      Statement(tree, state) {
        return { from: Math.min(tree.from + 100, state.doc.lineAt(tree.from).to), to: tree.to };
      },
      blockComment(tree) {
        return { from: tree.from + 2, to: tree.to - 2 };
      },
    }),
    styleTags({
      'TRUE FALSE': t.bool,
      NULL: t.null,
      [controlKeywords.join(' ')]: t.controlKeyword,
      [baseTypes.join(' ')]: t.typeName,
      [operators.join(' ')]: t.operator,
      [keywords.join(' ')]: t.keyword,
      [accums.join(' ')]: t.typeName,
      [atoms.join(' ')]: t.atom,
      // [functions.join(' ')]: t.function(t.name),
      Number: t.number,
      String: t.string,
      Identifier: t.name,
      GlobalAccumIdent: t.name,
      LocalAccumIdent: t.name,
      LineComment: t.lineComment,
      BlockComment: t.blockComment,
      AssignOp: t.operator,
      '< >': t.operator,
      ArithOp: t.arithmeticOperator,
      LogicOp: t.logicOperator,
      BitOp: t.bitwiseOperator,
      CompareOp1: t.compareOperator,
      CompareOp2: t.compareOperator,
      '; , :': t.separator,
      '.': t.derefOperator,
      '( )': t.paren,
      '{ }': t.brace,
      '[ ]': t.squareBracket,
    }),
  ],
});

export interface GSQLConfig {
  schema?: Schema;
}

import { useEffect, useState, forwardRef, useRef } from 'react';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import GraphResult, { GraphResultRef } from '@/pages/editor/result/GraphResult';
import { useSchema, useSchemaStyle } from '@/utils/graphEditor/useMetaHook';
import { EmptySchema } from '@/components/schemaDesigner/EmptySchema';
import { FullScreenButton, FullScreenExit, MenuHide } from '@/pages/home/icons';
import GraphIcon from '@/assets/graph-schema.svg';
import { GraphEvents } from '@tigergraph/tools-ui/esm/graph/type';
import IconButton from '@/components/IconButton';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { expand } from 'inline-style-expand-shorthand';
import { ShortcutsMenu } from '@tigergraph/app-ui-lib/shortcuts-menu';
import { buildShortcut } from '@/utils/schemaDesigner';
import { PLACEMENT, TRIGGER_TYPE } from 'baseui/popover';
import { ExternalLink, ExternalNode } from '@tigergraph/tools-models/gvis/insights';
import { GLOBAL_GRAPH_NAME, GSQLPrivilege } from '@tigergraph/tools-models';
import { useWorkspaceContext } from '@/contexts/workspaceContext.tsx';
import { Button } from '@tigergraph/app-ui-lib/button';
import InactiveStatus from '@/components/schemaDesigner/InactiveStatus';
import { MdKeyboard } from 'react-icons/md';

export interface SchemaDesignerProps {
  title?: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
  graphEvents?: GraphEvents;
  enableEditor?: boolean;
  enableCreate?: boolean;
  enableSave?: boolean;
  enableUndoRedo?: boolean;
  enableDelete?: boolean;
  enableShortcuts?: boolean;
  enableGraphSelect?: boolean;
  enableFullScreen?: boolean;
  enableHeader?: boolean;
  hideSearch?: boolean;
  handleCreateVertex?: () => void;
  handleUndo?: () => void;
  handleRedo?: () => void;
  confirmDeleteVerticesOrEdges?: (items: (ExternalNode | ExternalLink)[]) => void;
  showHideButton?: boolean;
  showCreateEdgeTooltip?: boolean;
  onHideSchema?: () => void;
  schemaJson?: any;
  styleJSON?: any;
  isInDrawer?: boolean;
  hideSchema?: boolean;
}

const SchemaDesigner = forwardRef<GraphResultRef, SchemaDesignerProps>((props, ref) => {
  const [css, theme] = useStyletron();
  const {
    title = 'Schema Designer',
    width = '662px',
    height = '100vh',
    backgroundColor = theme.colors['background.primary'],
    enableEditor = true,
    enableCreate = true,
    enableDelete = true,
    enableSave = true,
    enableUndoRedo = true,
    enableShortcuts = true,
    enableGraphSelect = true,
    enableFullScreen = true,
    enableHeader = true,
    hideSearch,
    graphEvents,
    handleCreateVertex,
    handleUndo,
    handleRedo,
    confirmDeleteVerticesOrEdges,
    showHideButton = false,
    showCreateEdgeTooltip = false,
    isInDrawer = false,
    onHideSchema,
    schemaJson,
    styleJSON,
    hideSchema = false,
  } = props;
  const { currentGraph, currentWorkspace, dbUser, refetchSimpleAuth } = useWorkspaceContext();
  const { data: schema, isFetching, refetch: refetchSchema } = useSchema(currentGraph, schemaJson);
  const { data: schemaStyle, refetch: refetchSchemaStyle } = useSchemaStyle(currentGraph, styleJSON);
  const { data: globalSchemaStyle, refetch: refetchGlobalSchemaStyle } = useSchemaStyle(GLOBAL_GRAPH_NAME);
  const schemaDesignerContainerRef = useRef<HTMLDivElement>(null);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  // Refetch the global schema style when graph changes
  useEffect(() => {
    refetchGlobalSchemaStyle();
  }, [currentGraph, refetchGlobalSchemaStyle]);

  const toggleFullScreen = () => {
    if (schemaDesignerContainerRef.current) {
      if (isFullScreen) {
        setIsFullScreen(false);
        schemaDesignerContainerRef.current.style.position = 'relative';
        if (!isInDrawer) {
          schemaDesignerContainerRef.current.style.width = width;
        } else {
          schemaDesignerContainerRef.current.style.width = '662px';
        }
        schemaDesignerContainerRef.current.style.height = height;
      } else {
        schemaDesignerContainerRef.current.style.width = '100vw';
        schemaDesignerContainerRef.current.style.height = '100vh';
        if (!isInDrawer) schemaDesignerContainerRef.current.style.position = 'fixed';
        schemaDesignerContainerRef.current.style.top = '0';
        schemaDesignerContainerRef.current.style.left = '0';
        setIsFullScreen(true);
      }
    }
  };

  return (
    <>
      <div
        ref={schemaDesignerContainerRef}
        className={css({
          width: width,
          height: height,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: backgroundColor ?? theme.colors['background.secondary'],
        })}
      >
        {enableHeader && (
          <h2
            className={css({
              fontSize: '16px',
              fontWeight: 500,
              fontFamily: 'Urbanist',
              color: `${theme.colors.gray1000}`,
              height: '57px',
              lineHeight: '57px',
              borderBottom: `1px solid ${theme.colors.divider}`,
              paddingLeft: '16px',
              paddingRight: '26px',
              display: 'flex',
              justifyContent: 'space-between',
            })}
          >
            <div
              className={css({
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              })}
            >
              <img src={GraphIcon} />
              {title}
            </div>
            <div
              className={css({
                display: 'flex',
                alignItems: 'center',
              })}
            >
              {enableFullScreen && (
                <IconButton
                  $style={{
                    border: `none`,
                    backgroundColor: '#fff',
                    height: '24px',
                    width: '24px',
                    marginRight: '8px',
                  }}
                  onClick={() => {
                    toggleFullScreen();
                  }}
                >
                  {isFullScreen ? <FullScreenExit /> : <FullScreenButton />}
                </IconButton>
              )}
              {showHideButton && (
                <IconButton
                  onClick={() => {
                    onHideSchema && onHideSchema();
                  }}
                  $style={{
                    height: '24px',
                    width: '24px',
                  }}
                >
                  <MenuHide />
                </IconButton>
              )}
            </div>
          </h2>
        )}
        <div
          className={css({
            position: 'relative',
            flexGrow: 1,
            flexBasis: 0,
            minHeight: 0,
            backgroundColor,
          })}
        >
          {currentGraph && !!schema && !isFetching && currentWorkspace?.status === 'Active' && (
            <GraphResult
              ref={ref}
              id={currentGraph}
              schema={schema}
              graphName={currentGraph}
              isSchemaGraph={true}
              graphEvents={graphEvents}
              enableEditor={
                enableEditor &&
                currentWorkspace.is_rw &&
                !!dbUser?.hasPrivilege(GSQLPrivilege.WriteSchema, currentGraph)
              }
              enableCreate={
                enableCreate &&
                currentWorkspace.is_rw &&
                !!dbUser?.hasPrivilege(GSQLPrivilege.WriteSchema, currentGraph)
              }
              enableDelete={
                enableDelete &&
                currentWorkspace.is_rw &&
                !!dbUser?.hasPrivilege(GSQLPrivilege.WriteSchema, currentGraph)
              }
              enableSave={
                enableSave &&
                currentWorkspace.is_rw &&
                !!dbUser?.hasPrivilege(GSQLPrivilege.WriteSchema, currentGraph) &&
                !hideSchema
              }
              enableUndoRedo={
                enableUndoRedo &&
                currentWorkspace.is_rw &&
                !!dbUser?.hasPrivilege(GSQLPrivilege.WriteSchema, currentGraph) &&
                !hideSchema
              }
              enableShortcuts={enableShortcuts}
              hideSearch={hideSearch}
              schemaStyle={schemaStyle}
              globalSchemaStyle={globalSchemaStyle}
              showCreateEdgeTooltip={showCreateEdgeTooltip}
              enableGraphSelect={enableGraphSelect}
              handleUndo={handleUndo}
              handleRedo={handleRedo}
              handleCreateVertex={handleCreateVertex}
              confirmDeleteVerticesOrEdges={confirmDeleteVerticesOrEdges}
              refetchSchema={() => {
                refetchSimpleAuth();
                refetchSchema();
                refetchSchemaStyle();
                refetchGlobalSchemaStyle();
              }}
            />
          )}
          {currentWorkspace?.is_rw && (
            <StatefulPopover
              placement={PLACEMENT.rightBottom}
              ignoreBoundary={false}
              showArrow={false}
              popoverMargin={8}
              overrides={{
                Body: {
                  style: {
                    ...expand({
                      // paddingTop: '8px',
                      // paddingBottom: '8px',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      borderRadius: '5px',
                    }),
                    backgroundColor: theme.colors['background.primary'],
                  },
                },
                Inner: {
                  style: {
                    backgroundColor: theme.colors['background.primary'],
                    ...expand({
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                    }),
                  },
                },
              }}
              content={() => {
                return (
                  <div
                    className={css({
                      boxSizing: 'content-box',
                      border: `1px solid ${theme.colors.divider}`,
                      borderRadius: '5px',
                    })}
                  >
                    <ShortcutsMenu
                      shortcuts={buildShortcut({
                        enableSave:
                          enableSave &&
                          currentWorkspace.is_rw &&
                          !!dbUser?.hasPrivilege(GSQLPrivilege.WriteSchema, currentGraph),
                      })}
                      column={1}
                    />
                  </div>
                );
              }}
            >
              <div
                className={css({
                  position: 'absolute',
                  top: 'calc(100vh - 106px)',
                  left: '16px',
                  zIndex: 2,
                })}
              >
                <StatefulPopover
                  triggerType={TRIGGER_TYPE.hover}
                  content={'Shortcuts'}
                  overrides={{
                    Body: {
                      style: {
                        ...expand({
                          margin: '10px',
                        }),
                      },
                    },
                  }}
                >
                  <Button
                    kind="secondary"
                    size="compact"
                    overrides={{
                      BaseButton: {
                        style: {
                          height: '24px',
                          width: '24px',
                          boxSizing: 'border-box',
                          ...expand({
                            padding: '1px',
                          }),
                        },
                      },
                    }}
                  >
                    <MdKeyboard size={20} />
                  </Button>
                </StatefulPopover>
              </div>
            </StatefulPopover>
          )}
          {currentWorkspace?.status !== 'Active' && <InactiveStatus />}
          {isFetching && (
            <div
              className={css({
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '596px',
                transform: 'translate(-50%, -50%)',
                pointerEvents: 'none',
              })}
            >
              <EmptySchema isLoading={true} />
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default SchemaDesigner;

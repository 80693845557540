// A list of org_id that is paying for the cloud v3
// For these orgs, redirect them to v3 by default
export const cloudV3Orgs = [
  // orgs for tests
  'org_NGbCeg6CIewQFKxi',
  'org_QMbMRYION6LbhhnY',
  'org_lSrNWagZNHUufCMN',

  // Nubank
  'org_oebCf19AqSzexgU8',
  'org_mKXkMZ71OExwz4Go',
  'org_BOJojv158kOQrW0Q',
  'org_vva4bkmlj5aKwAcY',
  'org_GOWVM8iZrEdyrLBC',

  // Persona
  'org_yDJiMkPIRz2HGH8U',
  'org_8FNGvZzXG9XrYRTO',
  'org_BKGE8LpfNNeQQfO7',
  'org_b0XMNMwRcdM2JFiN',
  'org_qXLtbjApCA7JlMix',
  'org_SlW0v1HX7vL3esHK',
  'org_hTf8ZsOxmzLER5wn',
  'org_AtYQiGiccmJzeMzZ',
  'org_Ewd1C87IZbdelDri',
  'org_PppKNAvAZb7eDT09',
  'org_iY9X2ybaxV0VhE99',
  'org_y8UDbWgF4FgvpJ4Q',
  'org_QYkjRjcJ8xXF0bln',
  'org_Fqb5ldaUqdtVhdit',

  // Sunbit
  'org_wKMLHAOJzXdqal2t',
  'org_eXsyMxcP8J1b0H6y',
  'org_eVcTca4N56RFGcKJ',
  'org_9I1NSfHoHWDf5gSb',

  // Kafene
  'org_XgEuca2RnDXMlrga',
  'org_IYqUlhlfycZ9Jejp',
  'org_DmyY7cHCbjfR2iut',
  'org_lJUXazPcLZeHelsz',
  'org_WwhDRar2deXqp0Kx',
  'org_qguNi1QkfpNlISIP',

  // AlvarezAndMarsal
  'org_BvPPTvRJhlBvfVpO',

  // Innerspace
  'org_ElER113UuYFb85HP',

  // IFB
  'org_opHpKUKzOPzeZ4xC',

  // Illumina
  'org_0pix4YGPqtKzrfGI',

  // Wearthat
  'org_SYjPnjvx5mK0cCcQ',

  // Newday
  'org_sJtM6sTt0dOaRbb5',

  // USXpress
  'org_7vqEmoz6ZYBy80U8',
];

import { Input } from '@tigergraph/app-ui-lib/input';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { useEffect, useState } from 'react';
import { expand } from 'inline-style-expand-shorthand';

export type EditableInputProps = {
  value: string | number;
  placeholder?: string;
  onChange: (value: string | number) => void;
  readOnly?: boolean;
  width?: string;
  autoFocus?: boolean;
  onBlur?: () => void;
};

export function EditableInput({ value, onChange, readOnly, width, placeholder, onBlur, ...props }: EditableInputProps) {
  const [css] = useStyletron();
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <Input
      onChange={(e) => {
        setInputValue(e.currentTarget.value);
      }}
      onBlur={() => {
        if (inputValue !== value) {
          onChange(inputValue);
        }
        onBlur?.();
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && inputValue !== value) {
          onChange(inputValue);
        }
        e.stopPropagation();
      }}
      placeholder={placeholder}
      value={inputValue}
      readOnly={readOnly}
      overrides={{
        Root: {
          style: ({ $isFocused }) => ({
            width: width || '128px',
            backgroundColor: 'inherit',
            ...expand({
              border: $isFocused ? '1px' : '0',
            }),
          }),
          props: {
            title: inputValue,
          },
        },
        InputContainer: {
          style: {
            backgroundColor: 'inherit',
          },
        },
        Input: {
          style: {
            height: '30px',
            paddingLeft: '2px',
            width: width || '128px',
            paddingRight: '0px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            fontSize: '12px',
          },
          props: {
            title: inputValue,
          },
        },
      }}
      {...props}
    />
  );
}

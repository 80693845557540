import React, { createContext, FC, Key, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { ActiveFile } from '@/utils/graphEditor/data';
import { debounce } from 'lodash-es';
import { Command } from '@/pages/editor/result/CommandExecutor';

interface GraphEditorContextValue {
  commandList: Command[];
  setCommandList: React.Dispatch<React.SetStateAction<Command[]>>;
  activeCmdId: React.Key;
  setActiveCmdId: React.Dispatch<React.SetStateAction<React.Key>>;
  activeFiles: ActiveFile[];
  setActiveFiles: React.Dispatch<React.SetStateAction<ActiveFile[]>>;
  currentFileId: string;
  setCurrentFileId: React.Dispatch<React.SetStateAction<string>>;
  folderExpanded: Record<string, boolean>;
  onToggleExpand: (id: string) => void;
  selectedCode: string;
  setSelectedCode: React.Dispatch<React.SetStateAction<string>>;
  unsavedFiles: ActiveFile[];
  selectedQueryId: string | null;
  setSelectedQueryId: React.Dispatch<React.SetStateAction<string | null>>;
  hideSchema: boolean;
  setHideSchema: React.Dispatch<React.SetStateAction<boolean>>;
  isCommandRunning: boolean;
  setIsCommandRunning: React.Dispatch<React.SetStateAction<boolean>>;
  currentRunningCommand: Command | null;
  setCurrentRunningCommand: React.Dispatch<React.SetStateAction<Command | null>>;

  memoryLimit: number;
  setMemoryLimit: React.Dispatch<React.SetStateAction<number>>;
  timeLimit: number;
  setTimeLimit: React.Dispatch<React.SetStateAction<number>>;
  profile: boolean;
  setProfile: React.Dispatch<React.SetStateAction<boolean>>;
}
export const GraphEditorContext = createContext<GraphEditorContextValue>({} as GraphEditorContextValue);

export const GraphEditorProvider: FC<PropsWithChildren> = (props) => {
  const [activeCmdId, setActiveCmdId] = useState<Key>(0);
  // activeFiles is currently open files
  // when open a file, we need to deep clone the file and add it to activeFiles
  const [activeFiles, setActiveFiles] = useState<ActiveFile[]>([]);
  const [currentFileId, setCurrentFileId] = useState<string>(
    new URLSearchParams(window.location.search).get('fileId') || ''
  );
  const [commandList, setCommandList] = useState<Command[]>([]);
  const [folderExpanded, setFolderExpanded] = useState<Record<string, boolean>>({});
  const onToggleExpand = useCallback(
    (id: string) => {
      setFolderExpanded((prev) => {
        return { ...prev, [id]: !prev[id] };
      });
    },
    [setFolderExpanded]
  );
  const [selectedCode, setSelectedCode] = useState('');
  const [selectedQueryId, setSelectedQueryId] = useState<string | null>(null);

  const unsavedFiles = useMemo(() => {
    return activeFiles.filter((file) => {
      // for temp file, if it has no file_id, it's unsaved
      return (file.is_temp && !file.file_id) || (file.sourceCode != undefined && file.content !== file.sourceCode);
    });
  }, [activeFiles]);

  const [hideSchema, setHideSchema] = useState(false);
  const [isCommandRunning, setIsCommandRunning] = useState<boolean>(false);
  const [currentRunningCommand, setCurrentRunningCommand] = useState<Command | null>(null);
  const [memoryLimit, setMemoryLimit] = useState<number>(0);
  const [timeLimit, setTimeLimit] = useState<number>(0);
  const [profile, setProfile] = useState<boolean>(true);

  const contextValue = {
    commandList,
    setCommandList,
    activeCmdId,
    setActiveCmdId,
    activeFiles,
    setActiveFiles,
    currentFileId,
    setCurrentFileId,
    folderExpanded,
    onToggleExpand,
    selectedCode,
    setSelectedCode: debounce(setSelectedCode, 400),
    unsavedFiles,
    selectedQueryId,
    setSelectedQueryId,
    hideSchema,
    setHideSchema,
    isCommandRunning,
    setIsCommandRunning,
    currentRunningCommand,
    setCurrentRunningCommand,
    memoryLimit,
    setMemoryLimit,
    timeLimit,
    setTimeLimit,
    profile,
    setProfile,
  };

  return <GraphEditorContext.Provider value={contextValue} {...props} />;
};

export const useEditorContext = () => {
  const context = useContext(GraphEditorContext);
  return context;
};

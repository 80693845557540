import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import {
  LoadToEdgeData,
  LoadToVertexData,
  LoadingJobData,
  OneColumnMappingDataSourceColumnSource,
  OneColumnMappingGraphEntityTarget,
  OneColumnMappingMappingWidgetSource,
  OneColumnMappingMappingWidgetTarget,
} from '@tigergraph/tools-models/loading-job';
import { Attribute } from '@tigergraph/tools-models/topology';
import { ValidateResult } from '@tigergraph/tools-models/utils';
import { ParagraphSmall } from 'baseui/typography';
import { EmbeddingAttribute } from '@tigergraph/tools-models/topology/embedding-attribute.model';
import AttributeEditor from '@/components/schemaDesigner/attributeEditor/AttributeEditor';
import EmbeddingAttributeEditor from '@/components/schemaDesigner/attributeEditor/EmbeddingAttributeEditor';

export interface AttributeEditorContainerProps {
  primaryAttrs: Attribute[];
  attributes: Attribute[];
  embeddingAttributes?: EmbeddingAttribute[];
  validateResult: ValidateResult | undefined;
  mappingValidateResult?: ValidateResult | undefined;
  loadingJob?: LoadingJobData;
  loadingStatement?: LoadToVertexData | LoadToEdgeData;
  readOnly?: boolean;
  maxHeight?: string;
  onSave: (attributes: Attribute[]) => void;
  onDeleteLoadingStatementMappings?: (indices: number[]) => void;
  onChangeLoadingStatementMapping?: (
    mapping: {
      mappingSource: OneColumnMappingDataSourceColumnSource | OneColumnMappingMappingWidgetSource;
      mappingTarget: OneColumnMappingGraphEntityTarget | OneColumnMappingMappingWidgetTarget;
    }[]
  ) => void;
}

export default function AttributeEditorContainer(props: AttributeEditorContainerProps) {
  const [css, theme] = useStyletron();
  const {
    primaryAttrs,
    attributes,
    embeddingAttributes,
    loadingJob,
    loadingStatement,
    readOnly,
    maxHeight,
    validateResult,
    mappingValidateResult,
    onSave,
    onDeleteLoadingStatementMappings,
    onChangeLoadingStatementMapping,
  } = props;

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
        minHeight: '180px',
      })}
    >
      <AttributeEditor
        primaryAttrs={primaryAttrs}
        attributes={attributes}
        validateResult={validateResult}
        mappingValidateResult={mappingValidateResult}
        loadingJob={loadingJob}
        loadingStatement={loadingStatement}
        readOnly={readOnly}
        maxHeight={maxHeight}
        onSave={onSave}
        onDeleteLoadingStatementMappings={onDeleteLoadingStatementMappings}
        onChangeLoadingStatementMapping={onChangeLoadingStatementMapping}
      />
      {embeddingAttributes && embeddingAttributes.length > 0 && (
        <EmbeddingAttributeEditor
          attributes={embeddingAttributes}
          validateResult={validateResult}
          readOnly={true}
          onSave={() => {}}
        />
      )}
      <ParagraphSmall
        className={css({
          textAlign: 'center',
          color: theme.colors['text.secondary'],
          marginBottom: '8px',
        })}
      >
        Click &quot;+&quot; to add attributes.
      </ParagraphSmall>
    </div>
  );
}

import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Attribute, ValidateResult, Vertex } from '@tigergraph/tools-models';
import { VertexStyle } from '@tigergraph/tools-models/topology';
import AttributeEditorContainer from '@/components/schemaDesigner/attributeEditor/AttributeEditorContainer';
import { TypeNameEditor } from '@/components/schemaDesigner/attributeEditor/TypeNameEditor';
import AttributeAdvancedSettingModal from '@/components/schemaDesigner/attributeEditor/AttributeAdvancedSettingModal';
import TooltipLabel from '@/components/TooltipLabel';
import { EmbeddingAttribute } from '@tigergraph/tools-models/topology/embedding-attribute.model';
import short from 'short-uuid';

export interface VertexEditorProps {
  initVertex: Vertex;
  onClose: () => void;
  onSave: (vertex: Vertex) => ValidateResult;
  onDeleteVertexIcon: (icon: string) => void;
  isOpen: boolean;
  readOnly?: boolean;
  attributeReadOnly?: boolean;
  styleReadOnly?: boolean;
  warningMessage?: string[];
}

export function VertexEditor(props: VertexEditorProps) {
  const [css, theme] = useStyletron();
  const {
    isOpen,
    onClose,
    onSave,
    onDeleteVertexIcon,
    initVertex,
    readOnly,
    attributeReadOnly,
    styleReadOnly,
    warningMessage,
  } = props;

  const [color, setColor] = useState(initVertex?.style?.fillColor || '#cccccc');
  const [icon, setIcon] = useState(initVertex?.style?.icon || '');
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [embeddingAttributes, setEmbeddingAttributes] = useState<EmbeddingAttribute[]>([]);
  const [vertexName, setVertexName] = useState('');
  const [validateResult, setValidateResult] = useState<ValidateResult>({ success: true });
  const [attributeEditorKey, setAttributeEditorKey] = useState(short.generate());
  const divRef = useRef<HTMLDivElement>();

  useEffect(() => {
    setVertexName(initVertex?.name || '');
    const attrs = initVertex?.attributes ? [...initVertex.attributes] : [];
    const primaryAttr = initVertex?.primaryId;
    if (primaryAttr) {
      attrs.unshift(primaryAttr);
    }
    if (initVertex && !initVertex?.style) {
      initVertex['style'] = new VertexStyle();
    }
    setAttributes(attrs);
    setEmbeddingAttributes(initVertex?.embeddingAttributes || []);
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    setColor(initVertex?.style?.fillColor || randomColor);
    setIcon(initVertex?.style?.icon || '');
    setValidateResult({ success: true });
    setAttributeEditorKey(short.generate());
  }, [initVertex]);

  const handleSave = useCallback(
    (vertexName: string, attributes: Attribute[], color: string, icon: string, hideValidateResult?: boolean) => {
      const vertex = initVertex.clone();
      vertex.name = vertexName;
      // remove the first attribute primary key
      const primaryId = attributes[0];
      vertex.primaryId = primaryId;
      vertex.attributes = attributes.slice(1);
      vertex.config = {
        ...vertex.config,
        PRIMARY_ID_AS_ATTRIBUTE: primaryId?.primaryIdAsAttribute,
      };
      if (!primaryId?.primaryIdAsAttribute) {
        delete vertex.config['PRIMARY_ID_AS_ATTRIBUTE'];
      }
      vertex.style.fillColor = color;
      vertex.style.icon = icon;
      const res = onSave(vertex);
      if (res?.success) {
        initVertex.loadFromGSQLJson(vertex.dumpToGSQLJson());
      }
      if (!hideValidateResult) {
        setValidateResult(res);
      }
      return res;
    },
    [initVertex, onSave]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  return (
    <div
      ref={divRef as RefObject<HTMLDivElement>}
      className={css({
        height: '100%',
        width: '100%',
        backgroundColor: theme.colors['background.primary'],
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px',
        padding: '16px 0',
      })}
    >
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        })}
      >
        <TooltipLabel label="Vertex Name" tooltip="Selected target vertex, you can update the vertex name and color." />
        <AttributeAdvancedSettingModal
          mountNode={divRef.current}
          name={vertexName}
          isVertex={true}
          attributes={attributes}
          readOnly={readOnly}
          onSaveVertex={(newAttributes) => {
            const res = handleSave(vertexName, newAttributes, color, icon, true);
            if (res?.success) {
              setAttributes(newAttributes);
            }
            return res;
          }}
        />
      </div>
      <TypeNameEditor
        isVertex={true}
        typeName={vertexName}
        color={color}
        icon={icon}
        readOnly={readOnly}
        styleReadOnly={styleReadOnly}
        warningMessage={warningMessage}
        validateResult={validateResult}
        onSaveVertex={(newName, newColor, newIcon) => {
          setVertexName(newName);
          setColor(newColor);
          setIcon(newIcon);
          handleSave(newName, attributes, newColor, newIcon);
        }}
        onDeleteVertexIcon={onDeleteVertexIcon}
      />
      <AttributeEditorContainer
        key={attributeEditorKey}
        primaryAttrs={[initVertex.primaryId]}
        attributes={attributes}
        embeddingAttributes={embeddingAttributes}
        validateResult={validateResult}
        readOnly={attributeReadOnly}
        onSave={(newAttributes) => {
          setAttributes(newAttributes);
          handleSave(vertexName, newAttributes, color, icon);
        }}
      />
    </div>
  );
}

import { useState, useCallback, useMemo } from 'react';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Table } from '@tigergraph/app-ui-lib/table';
import { ValidateResult } from '@tigergraph/tools-models/utils';
import { Checkbox } from '@tigergraph/app-ui-lib/checkbox';
import { buildEmbeddingAttributeEditorRow } from '@/components/schemaDesigner/attributeEditor/buildAttributeEditorRow';
import TooltipLabel from '@/components/TooltipLabel';
import { Button } from '@tigergraph/app-ui-lib/button';
import { randomString } from '@/utils/schemaDesigner';
import { expand } from 'inline-style-expand-shorthand';
import { MdDeleteOutline } from 'react-icons/md';
import { Plus } from 'baseui/icon';
import ErrorMessage from '@/components/ErrorMessage';
import ConfirmModal from '@/components/ConfirmModal';
import { mergeOverrides } from 'baseui';
import { SchemaAttributeTableWithSelect } from '@/utils/schemaDesigner/styleObject';
import { Overrides } from 'baseui/overrides';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { TRIGGER_TYPE } from 'baseui/popover';
import { EmbeddingAttribute } from '@tigergraph/tools-models/topology/embedding-attribute.model';

export interface EmbeddingAttributeEditorProps {
  attributes: EmbeddingAttribute[];
  validateResult: ValidateResult | undefined;
  readOnly?: boolean;
  maxHeight?: string;
  onSave: (attributes: EmbeddingAttribute[]) => void;
}

export default function EmbeddingAttributeEditor(props: EmbeddingAttributeEditorProps) {
  const [css, theme] = useStyletron();
  const { attributes, readOnly, maxHeight, validateResult, onSave } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [deleteSelection, setDeleteSelection] = useState<{ [index: number]: boolean }>({});

  const handleAddAttribute = () => {
    const attr = new EmbeddingAttribute();
    attr.name = `newAttr_${randomString(3)}`;
    const newAttributes = [...attributes, attr];
    onSave(newAttributes);
  };

  const handleDeleteAttributesConfirm = () => {
    setOpenDialog(true);
  };

  const handleDeleteAttributes = () => {
    const newAttributes = [];
    for (let i = 0; i < attributes.length; i++) {
      if (!(i in deleteSelection)) {
        newAttributes.push(attributes[i]);
      }
    }
    setDeleteSelection({});
    onSave(newAttributes);
  };

  const handleDeleteSelection = useCallback((index: number) => {
    setDeleteSelection((prevDeleteSelection) => {
      const isCheck = !prevDeleteSelection[index];
      if (isCheck) {
        return {
          ...prevDeleteSelection,
          [index]: !prevDeleteSelection[index],
        };
      } else {
        delete prevDeleteSelection[index];
        return { ...prevDeleteSelection };
      }
    });
  }, []);

  const handleHeaderDeleteSelection = useCallback(() => {
    setDeleteSelection((prevDeleteSelection) => {
      if (Object.keys(deleteSelection).length === attributes.length) {
        return {};
      } else {
        for (let i = 0; i < attributes.length; i++) {
          prevDeleteSelection[i] = true;
        }
        return { ...prevDeleteSelection };
      }
    });
  }, [attributes.length, deleteSelection]);

  const isInvalidAttribute = useMemo(() => {
    return (
      validateResult &&
      !validateResult.success &&
      !validateResult.message?.includes('vertex name') &&
      !validateResult.message?.includes('edge name')
    );
  }, [validateResult]);

  const tableHeader = useMemo(() => {
    const tableHeader = [
      <Checkbox
        key={'delete_checkbox'}
        isIndeterminate={
          Object.keys(deleteSelection).length > 0 && Object.keys(deleteSelection).length !== attributes.length
        }
        checked={Object.keys(deleteSelection).length > 0 && Object.keys(deleteSelection).length === attributes.length}
        disabled={readOnly}
        onChange={handleHeaderDeleteSelection}
      />,
      'Name',
      'EmbeddingAttrId',
      'Dimension',
      'Metric',
      'IndexType',
      'DataType',
    ];
    return tableHeader;
  }, [attributes.length, deleteSelection, handleHeaderDeleteSelection, readOnly]);

  const tableData = useMemo(
    () =>
      attributes.map((attribute, index) => {
        const row = buildEmbeddingAttributeEditorRow({
          css,
          theme,
          attribute,
          deleting: !!deleteSelection[index],
          readOnly: readOnly,
          handleAttributeChange: (newAttribute) => {
            const newAttributes = [...attributes];
            newAttributes[index] = newAttribute;
            onSave(newAttributes);
          },
          handleDeleteSelection: () => handleDeleteSelection(index),
        });

        return row;
      }),
    [attributes, css, deleteSelection, handleDeleteSelection, onSave, readOnly, theme]
  );

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
      })}
    >
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        })}
      >
        <TooltipLabel label="Embedding Attributes" tooltip={"Don't support editing embedding attributes yet."} />
        <StatefulPopover
          triggerType={TRIGGER_TYPE.hover}
          content={Object.keys(deleteSelection).length > 0 ? 'Delete embedding attribute' : 'Add embedding attribute'}
          overrides={{
            Body: {
              style: {
                ...expand({
                  margin: '10px',
                }),
              },
            },
          }}
        >
          <Button
            aria-label={
              Object.keys(deleteSelection).length > 0 ? 'Delete embedding attribute' : 'Add embedding attribute'
            }
            onClick={Object.keys(deleteSelection).length > 0 ? handleDeleteAttributesConfirm : handleAddAttribute}
            disabled={readOnly}
            size="default"
            kind={Object.keys(deleteSelection).length ? 'destructive' : 'secondary'}
            overrides={{
              BaseButton: {
                style: {
                  ...expand({
                    padding: '3px',
                  }),
                },
              },
            }}
          >
            {Object.keys(deleteSelection).length > 0 ? <MdDeleteOutline size={16} /> : <Plus size={16} />}
          </Button>
        </StatefulPopover>
      </div>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          rowGap: '8px',
        })}
      >
        <Table
          divider={'horizontal'}
          overrides={mergeOverrides(SchemaAttributeTableWithSelect(theme) as Overrides<any>, {
            Root: {
              style: {
                maxHeight: maxHeight ?? '100%',
                overflow: 'auto',
              },
            },
            TableBodyRow: {
              style: ({ $rowIndex }) => {
                const match = validateResult?.message?.match(/"([^"]*)"/);
                if (match && match[1] !== undefined) {
                  const quotedWord = match[1];
                  if ($rowIndex === attributes.findIndex((attribute) => attribute.name === quotedWord)) {
                    return {
                      border: `2px solid ${theme.colors.negative}`,
                    };
                  }
                }
                return {};
              },
            },
          })}
          columns={tableHeader}
          data={tableData}
        />
        {isInvalidAttribute && <ErrorMessage message={validateResult?.message ?? ''} />}
      </div>
      <ConfirmModal
        open={openDialog}
        header="Warning"
        body={`Are you sure you want to delete the selected embedding attribute${
          Object.keys(deleteSelection).length > 1 ? 's' : ''
        }?`}
        onConfirm={() => {
          setOpenDialog(false);
          handleDeleteAttributes();
        }}
        onCancel={() => setOpenDialog(false)}
      />
    </div>
  );
}
